import React, { ReactElement, useState } from 'react';
import { Container, Content, SidebarLayout } from './style';
// import { SideBar } from '..';
import { Navbar } from '../../components';
import { SideBar, ZojaSideBar } from '..';
import { cn } from '../../lib/utils';

export interface IProps {
  children: ReactElement;
  navTitle: string | any;
  navHelper?: string | any;
  goBack?: () => void;
  navBarContentRight?: any;
  className?: string
}

function AppContainer({ children, navTitle, navHelper, goBack, navBarContentRight, className }: IProps) {
  const [hidden, setHidden] = useState(true);
  return (
    <>
      <Container className={cn(
        className
      )}>
        <Navbar onClick={() => setHidden(!hidden)} goBack={goBack} title={navTitle} helper={navHelper} navBarContentRight={navBarContentRight} />
        <Content>
          {/* <SidebarLayout hide={hidden}> */}
            {/* <SideBar onClick={() => setHidden(!hidden)} /> */}
            <ZojaSideBar
              showSidebar={hidden}
              setShowSidebar={setHidden}
            />
          {/* </SidebarLayout> */}
          {children}
        </Content>
      </Container>
      
    </>
  );
}

export default AppContainer;