export const ApprovalTabs = [
    {
      id: 1,
      title: 'Stage 1 Approvals',
      url: 'step-1',
    },
    {
      id: 2,
      title: 'Stage 2 Approvals',
      url: 'step-2',
    },
  ];