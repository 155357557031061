import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { PasswordChecker, TogglePassword, ZojalActionModal } from '../../components';
import { ZojaButton, ZojaInput } from '../../components/tailwind';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { adminClaimAccountRequest, adminClaimAccountReset, resendToken,userPasswordResetRequest } from '../../redux/slice';
import { H2 } from '../../styles';
import { Dictionary } from '../../types';
import { colors, containSpecialChar, containsLowerCase, containsNumber, containsUpperCase,showMessage, containsUpperLowerNumber, images, routesPath, spacing } from '../../utils';
import { Container, ContainerContent, Content } from '../login/style';

const SPECIAL_CHARACTERS = ['#', '@', '/']

const { LOGIN } = routesPath;
function ClaimAccount() {
  const [showPassword, setShowPassword] = useState(false)
  const [passwordNotStrong, setPasswordNotStrong] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const adminClaimAccountState = useAppSelector((state) => state.userPasswordReset)
  const { status: adminClaimAccountStatus } = adminClaimAccountState

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParam, setSearchParam] = useSearchParams()

  const schema = yup.object().shape({
    name: yup.string().required('Full Name is required'),
    email: yup.string().email().required('Email is required'),
    password: yup.string().required('Password is required'),
    token: yup.string().required('Token is required'),
    password_confirmation: yup.string()
      .oneOf([yup.ref('password')], 'Password must match').required('Confirm your password'),
  });

  const handleFormSubmit = (values:any) => {
    if (!containSpecialChar(values.password, SPECIAL_CHARACTERS) && !containsUpperLowerNumber(values.password)) {
      setPasswordNotStrong(true)
      return
    }
    dispatch(userPasswordResetRequest(values))
  }

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: {
      name: '',
      email: searchParam.get('email') || '',
      password: '',
      password_confirmation: '',
      token: ""
    },
    validationSchema: schema,
    onSubmit: handleFormSubmit
  })


  useEffect(() => {
    if (containSpecialChar(values.password, SPECIAL_CHARACTERS)
      && values.password === values.password_confirmation && containsUpperLowerNumber(values.password)
    ) {
      setPasswordNotStrong(false)
    }
  }, [values.password, values.password_confirmation])


  useEffect(() => {
    if (adminClaimAccountState?.status === "succeeded") {
      setShowSuccessModal(true)
    }
  },[adminClaimAccountState?.status])


  return (
    <section>
      <Container>
        <ContainerContent className='md:!tw-w-[29rem]'>
          <Content>
            <H2 center bold color={colors.primary} style={{ marginBottom: spacing.small_3 }}>
              Complete Profile Setup
            </H2>
            <p className='tw-text-xs tw-mb-6 tw-w-4/5 mx-auto tw-text-center'>Provide your full name, Personalize and save your preferred password, and proceed to login with newly saved password</p>
            <form onSubmit={handleSubmit}>
              <div className='tw-mb-3'>
                <ZojaInput
                  label="Full Name"
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  value={values.name}
                  onChange={handleChange}
                  touched={touched}
                  error={errors.name}
                  extraClass='placeholder:tw-pl-[1px tw-p-2.5 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-text-gray-600 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-isPrimary'
                  labelExtraClass='tw-text-[14px]'
                />
              </div>

              <div className='tw-mb-3'>
                <ZojaInput
                  label="Email"
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email}
                  disabled
                  extraClass='placeholder:tw-pl-[1px tw-p-2.5 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-text-gray-600 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-isPrimary'
                  labelExtraClass='tw-text-[14px]'
                />
              </div>

              <div className='tw-mb-3 tw-relative'>
                <ZojaInput
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  touched={touched}
                  error={errors.password}
                  extraClass='placeholder:tw-pl-[1px tw-p-2.5 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-text-gray-600 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-isPrimary'
                  labelExtraClass='tw-text-[14px]'
                />
                <TogglePassword showPassword={showPassword} setShowPassword={setShowPassword} />
              </div>

              <div className='tw-mb-4 tw-relative'>
                <ZojaInput
                  label="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  name="password_confirmation"
                  placeholder="Confirm Password"
                  value={values.password_confirmation}
                  onChange={handleChange}
                  touched={touched}
                  error={errors.password_confirmation}
                  extraClass='placeholder:tw-pl-[1px tw-p-2.5 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-text-gray-600 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-isPrimary'
                  labelExtraClass='tw-text-[14px]'
                />
                <TogglePassword showPassword={showPassword} setShowPassword={setShowPassword} />
              </div>
              <div className='tw-mb-3'>
                <ZojaInput
                  label="Token"
                  type="text"
                  name="token"
                  placeholder="Token"
                  value={values.token}
                  onChange={handleChange}
                  error={errors.token}
                  extraClass='placeholder:tw-pl-[1px tw-p-2.5 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-text-gray-600 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-isPrimary'
                  labelExtraClass='tw-text-[14px]'
                />
              </div>
              <div onClick={async () => {
                                        if (!values?.email) {
                                            showMessage({
                                                type: 'error',
                                                message: "Email is required to resend token"
                                            })
                                            return;
                                        }
                                        await dispatch(resendToken(values?.email));

                                    }} className="d-flex justify-content-end align-items-center pb-4">Resend Token</div>


              {passwordNotStrong && <span className='tw-block tw-text-xs tw-text-red-500 tw-text-center'>Password not strong (must contain uppercase letter, lowercase letter, number and a symbol(!@#))</span>}

              <div className='tw-grid tw-grid-cols-2 tw-gap-2 tw-mb-3'>
                {values.password &&
                  <>
                    <PasswordChecker
                      isValid={containsUpperCase(values.password)}
                      title='One uppercase character'
                    />
                    <PasswordChecker
                      isValid={containsLowerCase(values.password)}
                      title='One lowercase character'
                    />
                    <PasswordChecker
                      isValid={containsNumber(values.password)}
                      title='One number'
                    />
                    <PasswordChecker
                      isValid={containSpecialChar(values.password, SPECIAL_CHARACTERS)}
                      title='One special character'
                    />
                  </>
                }
              </div>

              <ZojaButton
                type="submit"
                text="Submit"
                loading={adminClaimAccountStatus === "loading"}
                disabled={adminClaimAccountStatus === "loading"}
                extraClass='tw-mt-3 tw-bg-isPrimary tw-text-white tw-font-thin tw-w-full tw-grid tw-place-content-center tw-p-3'
              />

              <p className='tw-mt-3 tw-text-xs tw-text-gray-600 tw-text-center'>Remember to add an Uppercase letter, Lowercase letter, Number and symbol(!@#)</p>
              <p className='tw-mt-3 tw-text-center'>Already have an account? <Link to={LOGIN}>Login</Link></p>
            </form>
          </Content>
        </ContainerContent>
      </Container>
      <ZojalActionModal
        show={showSuccessModal}
        handleClose={() => {
          setShowSuccessModal(false)
          dispatch(adminClaimAccountReset())
          navigate(LOGIN)
        }}
        showCloseIcon={false}
        modalClass='!tw-rounded-[1rem] xl:tw-w-[32rem]'
        children={<>
          <div className='tw-py-6'>
            <div className='tw-mb-3 tw-flex tw-flex-col tw-justify-center tw-items-center'>
              <img src={images.successIconGif} className='tw-w-[40%]' alt="success-icon" />
              <h3 className='tw-text-[1.4rem] tw-text-gray-800'>Account Created</h3>
              <p className='tw-text-sm tw-w-4/5'>Your account has been created successfully, proceed to login with your credentials</p>
            </div>
            <ZojaButton
              type="button"
              text={'Proceed To Login'}
              extraClass={`tw-bg-isPrimary tw-text-white tw-py-[10px] tw-px-10 tw-text-sm`}
              loadingClass="tw-justify-center"
              onClick={() => {
                setShowSuccessModal(false)
                dispatch(adminClaimAccountReset())
                navigate(LOGIN)
              }}
            />
          </div>
        </>}
      />
    </section>
  );
}

export default ClaimAccount;
