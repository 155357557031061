import { memo, useState, useRef, useEffect } from 'react';
import { Container, Content, ImgContent, Divider, BackBtnContainer, HamburgerContainer } from './style';
import { H1, H4 } from '../../styles';
import { colors, images } from '../../utils';
import { FiArrowLeft } from 'react-icons/fi';
import { NotificationIcon, NotificationModal, SingleNotification } from '../../components';
import { RxHamburgerMenu } from 'react-icons/rx';
import { initializeAudio, playNotificationSound } from "../../utils/notificationSound";
import { useAppSelector } from '../../redux/redux-hooks';
import Pusher from "pusher-js";
import { ZojaModal } from '../../components/tailwind';
import {  getNotificationsList } from '../../redux/slice';
import { toast } from 'react-toastify';
import CustomToast from "../../atoms/CustomToast/index"
import { useAppDispatch } from '../../redux';
import { Dictionary } from '../../types';
import Cookies from 'js-cookie'
interface IProps {
  title: string;
  helper?: string;
  goBack?: () => void;
  onClick?: () => void;
  navBarContentRight?: any
}

function Navbar({ title, helper, goBack, onClick, navBarContentRight }: IProps) {
  const [showNotificationModal,setShowNotificationModal] = useState(false)
  const notificationModalRef = useRef<any>(null);
  const { data: dashboard_data, status } = useAppSelector(state => state.dashboard);
  const { data: dataNotification, status: statusNotification } = useAppSelector(state => state.dashboardNotification);
  const pusher_app_key = "4f07eceda0cc740b59d5";
  const pusher_app_cluster = "mt1";
  const pusher_app_event = "approval.update";
  const dispatch = useAppDispatch();
  const pusher_channel_name = 'user_' + Cookies.get('x-id')
  const [pushNotificationData,setPushNotificationData] = useState<any>([])


  useEffect(() => {
    const pusher = new Pusher(pusher_app_key, {
        cluster: pusher_app_cluster,
    });
    pusher.connection.bind("state_change", (states: any) => {
    });

    // Listen for successful connection
    pusher.connection.bind("connected", () => {
    });

    // Listen for disconnection
    pusher.connection.bind("disconnected", () => {
    });

    const channel = pusher.subscribe(pusher_channel_name);
    channel.bind(pusher_app_event, (data: any) => {
      pushNotificationData?.push(data)
        notify()
    });

    return () => {
        channel.unbind_all();
        channel.unsubscribe();
        pusher.disconnect();
    };
}, []);
const payload: Dictionary = {
  start_date: '',
  end_date: '',
  transaction_type: '',
  user_type: '',
};



const notify = () => {
    initializeAudio(); // Initialize on user interaction
    toast(<CustomToast />);
    playNotificationSound();
    dispatch(getNotificationsList(payload));

};


useEffect(() => {

  const handleFirstInteraction = () => {
      initializeAudio(); // Initialize on user interaction
      window.removeEventListener("click", handleFirstInteraction);
  };

  window.addEventListener("click", handleFirstInteraction);

  return () => {
      window.removeEventListener("click", handleFirstInteraction);
  };
}, []);



  return (
    <Container>
      <Content>
        <HamburgerContainer>
          <RxHamburgerMenu onClick={onClick} />
        </HamburgerContainer>
        {helper ? (
          <BackBtnContainer onClick={goBack}>
            <FiArrowLeft size={20} color={colors.grey} />
            <H4 color={colors.grey}>{title}</H4>
          </BackBtnContainer>
        ) : (
          <H1 semiBold>{title}</H1>
        )}
        {helper && <Divider />}
        {helper ? <H1 semiBold>{helper}</H1> : <H4>{helper}</H4>}
      </Content>

      <div className='tw-flex tw-items-center tw-gap-x-2'>

        {navBarContentRight}
        <NotificationIcon onClick={() => setShowNotificationModal(true)} />
        <ImgContent src={images.logoBordered} alt="logo" />
      </div>
      <ZojaModal
          show={showNotificationModal}
          height="full"
          position="right"
          handleClose={() => setShowNotificationModal(false)}
          extraClass="tw-w-[18rem] md:tw-w-[25rem]"
          closeOnClickOutside={true}
          contentRef={notificationModalRef}
          children={
            <NotificationModal
              title="Notification"
              contentRef={notificationModalRef}
              handleClose={() => setShowNotificationModal(false)}
              children={
                <div className='notif-scroll'>
                  {dataNotification.notification?.length > 0 ?
                    <div>
                      {dataNotification.notification?.map((data: any) => {
                        return <SingleNotification
                          title={data?.data?.title}
                          content={data?.data?.message}
                          created_at={data?.created_at}
                        />
                      })}
                    </div> :
                    <div className="flex justify-center items-center h-screen pt-5 mt-5">
                      <p className='text-center'>No Notifications yet.</p>
                    </div>}


                </div>
              }
            />
          }
        />
    </Container>
  );
}

export default memo(Navbar);
