import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppContainer, CountInfo } from '../../atoms';
import sample_image from '../../assets/images/sample_image.svg';
import { UserContainer, UsersContainer, TableContainer, SearchContainer } from './style';
import { Dictionary } from '../../types';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  getUsersRequest,
  getSuperAgentsRequest,
  // getInternalUsersRequest,
  downloadUsersRecord,
  getPhoneVerificationRequest,
  approveChangeVerificationRequest,
  approveChangeVerificationReset,
} from '../../redux/slice';
import { Oval } from 'react-loader-spinner';
import {
  SearchInput,
  Pagination,
  // BorderedText,
  ExportLabel,
  FilterCard,
  FilterLabel,
  ZojaCheckbox,
  FilterColapsible,
  CustomDatePicker,
  CustomersUsersTable,
  ImageUploadModal,
} from '../../components';
import {
  activeUsersTableHeaders,
  inActiveUsersTableHeaders,
  superAgentsTableHeaders,
  activeUserSearchType,
  inactiveUserSearchType,
  superAgentUserSearchType,
  pendingVerificationTableHeaders,
  pendingVerificationData,
} from './data';
import {
  // colors,
  dateFormat,
  routesPath,
  // spacing,
  icons,
  handleSelectedOption,
  formatStartAndEndDateWithDelimeter,
  useSortTable,
  capitalizeFirstLetter,
  useDelayedFunction,
  extractQueryParamValue,
  images,
} from '../../utils';
import { ZojaButton, ZojaModal, ZojaSelect } from '../../components/tailwind';

const { CUSTOMERDETAILS, CUSTOMERS } = routesPath;

const activeUser = 'active';
const inActiveUser = 'inactive';
const superAgentUser = 'super_agent';
const pending = 'pending';

const userTypeToFetchByActivity = (data: Dictionary) => {
  let result: string = '';
  if (!data?.hasOwnProperty('id') || data?.id === 1) {
    result = activeUser;
  }

  if (data?.id === 2) {
    result = superAgentUser;
  }

  if (data?.id === 3) {
    result = inActiveUser;
  }
  if (data?.id === 4) {
    result = pending;
  }

  return result;
};

// filter options constants
const sortByOptions = {
  aphabetically: [
    {
      id: 1,
      value: 'az',
      title: 'A-Z',
      isChecked: false,
    },
    {
      id: 2,
      value: 'za',
      title: 'Z-A',
      isChecked: false,
    },
    {
      id: 3,
      value: 'kyc_level',
      title: 'Kyc Level',
      isChecked: false,
    },
    {
      id: 4,
      value: 'verification_entry',
      title: 'Verification Entry',
      isChecked: false,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
    {
      id: 3,
      value: 'last_seen',
      title: 'Last Seen',
      isChecked: false,
    },
  ],
};

const initialValues: Dictionary = {
  status: '',
  kyc_level: '',
  last_seen: '',
  sort_by: '',
  order_by: '',
  date_onboarded: '',
  deactivated_at: '',
  verification_entry: '',
  search: '',
};

const memberAndLastSeenOptions = [
  {
    id: 1,
    value: 'today',
    title: 'Today',
    isChecked: false,
  },
  {
    id: 2,
    value: 'yesterday',
    title: 'Yesterday',
    isChecked: false,
  },
  {
    id: 3,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 4,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 5,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 6,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

const dateDeactivatedOptions = [
  {
    id: 1,
    value: 'today',
    title: 'Today',
    isChecked: false,
  },
  {
    id: 2,
    value: 'yesterday',
    title: 'Yesterday',
    isChecked: false,
  },
  {
    id: 3,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 4,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 5,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 6,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

const LastSeenOptions = [
  {
    id: 1,
    value: 'today',
    title: 'Today',
    isChecked: false,
  },
  {
    id: 2,
    value: 'yesterday',
    title: 'Yesterday',
    isChecked: false,
  },
  {
    id: 3,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 4,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 5,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 6,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

const kycFilterOptions = [
  { value: 'level_zero', label: 'Level 0', isChecked: false, id: 1 },
  { value: 'basic', label: 'Level 1', isChecked: false, id: 2 },
  { value: 'intermediate', label: 'Level 2', isChecked: false, id: 3 },
  { value: 'premium', label: 'Level 3', isChecked: false, id: 4 },
];

const verificationEntryFilterOptions = [
  { value: 'bvn', label: 'BVN', isChecked: false, id: 1 },
  { value: 'bvn_image', label: 'BVN Image', isChecked: false, id: 2 },
];

function findIndexByTitle(data: Dictionary[], searchString: string) {
  if (searchString === ``) return 0;

  for (let i = 0; i < data.length; i++) {
    if (data[i].keyId === searchString) {
      return i;
    }
  }

  return 0;
}

const initialUserCountData = [
  {
    id: 1,
    count: 0,
    title: 'Active Users',
    keyId: 'active',
  },
  {
    id: 2,
    count: 0,
    title: 'Super Agents',
    keyId: 'superAgent',
  },
  {
    id: 3,
    count: 0,
    title: 'Inactive Users',
    keyId: 'inactive',
  },
  {
    id: 4,
    count: 0,
    title: 'Pending Validations',
    keyId: 'pending',
  },
];

function Customers() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchDelayLength = 1200;
  const { delayedFunction } = useDelayedFunction(searchDelayLength);

  const { search } = useLocation();
  const viaType = !search ? '' : extractQueryParamValue(search);
  const initialSelectedCardIndex = findIndexByTitle(initialUserCountData, viaType);

  const [userCountData, setUserCountData] = useState<any[]>(initialUserCountData);
  const [selectedUsersCard, setSelectedUsersCard] = useState<Dictionary>(
    initialUserCountData[initialSelectedCardIndex],
  );

  const [searchValue, setSearchValue] = useState('');
  const [usersData, setUsersData] = useState<any[]>([]);
  const [usersDataSuperAgent, setUsersDataSuperAgent] = useState<any[]>([]);
  const [usersDataPending, setUsersDataPending] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [totalPagesSuperAgent, setTotalPagesSuperAgent] = useState(5);
  const [totalPagesPending, setTotalPagesPending] = useState(5);
  const [firstMount, setFirstMount] = useState(true);
  const [approveValidationStatus, setApproveValidationStatus] = useState('');
  // const [toggleGetInternalUser, setToggleGetInternalUser] = useState(false);

  //filters and selectors and input states
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showSortBy, setShowSortBy] = useState(false);
  const [showDateOnboarded, setShowDateOnboarded] = useState(false);
  const [showDateDeactivatedFilter, setShowDateDeactivatedFilter] = useState(false);
  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);
  const [showKYCLevelFilter, setShowKYCLevelFilter] = useState(false);
  const [verificationEntryFilter, setVerificationEntryFilter] = useState(false);
  const [showLastSeen, setShowLastSeen] = useState(false);
  const [sortBy, setSortBy] = useState(sortByOptions.aphabetically);
  const [onboardedDateRange, setOnboardedDateRange] = useState({ state_date: '', end_date: '' });
  const [lastSeenDateRange, setLastSeenDateRange] = useState({ state_date: '', end_date: '' });
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [dateOnboarded, setDateOnboarded] = useState(memberAndLastSeenOptions);
  const [dateDeactivated, setDateDeactivated] = useState(dateDeactivatedOptions);
  const [dateRange, setDateRange] = useState(dateDeactivatedOptions);
  const [kycFilterLevelState, setKycFilterLevelState] = useState(kycFilterOptions);
  const [verificationEntryState, setVerificationEntryState] = useState(verificationEntryFilterOptions);
  const [orderBy, setOrderBy] = useState(sortByOptions.orderBy);
  const [lastSeen, setLastSeen] = useState(LastSeenOptions);
  const [showImageUploadModal, setShowImageUploadModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any[]>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [order, setOrder] = useState('ASC');
  const [superAgentOrder, setSuperAgentOrder] = useState('ASC');

  // redux state
  const usersState = useAppSelector(state => state.getUsers);
  const { status: usersStatus } = usersState;

  const superAgentsState = useAppSelector(state => state.getSuperAgents);
  const { status: superAgentsStatus } = superAgentsState;

  const pendingState = useAppSelector(state => state.getPhoneVerification);
  const { status: pendingStateStatus } = pendingState;

  const approveChangeVerificationState = useAppSelector(state => state.approveChangeVerification);
  const { status: approveChangeVerificationStatus } = approveChangeVerificationState;

  const { status: downloadStatus } = useAppSelector(state => state.downloadUsersRecord);

  // get users by status
  const userTypeToFetch = userTypeToFetchByActivity(selectedUsersCard);

  const { handleSortItems } = useSortTable({ order: order, setOrder, data: usersData, setData: setUsersData });

  const { handleSortItems: handleSuperAgentSort } = useSortTable({
    order: superAgentOrder,
    setOrder: setSuperAgentOrder,
    data: usersDataSuperAgent,
    setData: setUsersDataSuperAgent,
  });

  const handlePageSizeChange = (perPageSize: number) => {
    let payload = { ...filterOptions };
    setCurrentPage(1);
    setPageSize(perPageSize);

    if (selectedUsersCard.id === 1) {
      dispatch(
        getUsersRequest({
          path: `/sort-by-status?status=active`,
          filter: { ...payload },
          per_page: perPageSize,
          page: 1,
        }),
      );
    }

    if (selectedUsersCard.id === 2) {
      dispatch(
        getSuperAgentsRequest({
          filter: { ...payload },
          per_page: perPageSize,
          page: 1,
        }),
      );
    }

    if (selectedUsersCard.id === 3) {
      dispatch(
        getUsersRequest({
          path: `/sort-by-status?status=inactive`,
          filter: { ...payload },
          per_page: perPageSize,
          page: 1,
        }),
      );
    }

    if (selectedUsersCard.id === 4) {
      dispatch(
        getPhoneVerificationRequest({
          filter: { ...payload },
          per_page: perPageSize,
          page: 1,
        }),
      );
    }
  };

  // This shows different sort options depending on userTypeToFetch
  useEffect(() => {
    let newState = [...sortByOptions.aphabetically];
    if (userTypeToFetch !== 'pending') {
      const filteredList = newState.filter(f => f.id !== 4);
      setSortBy(filteredList);
    }
    if (userTypeToFetch === 'pending') {
      const filteredList = newState.filter(f => f.id !== 3);
      setSortBy(filteredList);
    }
  }, [userTypeToFetch]);

  // filter table handler
  const handleSubmitFilter = () => {
    setCurrentPage(1);

    const onboarded_date_range = formatStartAndEndDateWithDelimeter(
      onboardedDateRange.state_date,
      onboardedDateRange.end_date,
    );
    const lastseen_date_range = formatStartAndEndDateWithDelimeter(
      lastSeenDateRange.state_date,
      lastSeenDateRange.end_date,
    );
    let payload = { ...filterOptions };

    payload = payload.date_onboarded === 'custom' ? { ...payload, date_onboarded: onboarded_date_range } : payload;
    payload = payload.last_seen === 'custom' ? { ...payload, last_seen: lastseen_date_range } : payload;

    if (userTypeToFetch === 'super_agent') {
      dispatch(
        getSuperAgentsRequest({
          filter: { ...payload },
          per_page: pageSize,
          page: 1,
        }),
      );
    } else {
      dispatch(
        getUsersRequest({
          path: `/sort-by-status?status=${userTypeToFetch}`,
          filter: { ...payload },
          per_page: pageSize,
          page: 1,
        }),
      );
    }
  };

  //search field table handler
  const handleSearchUser = () => {
    let payload = { ...filterOptions };
    setCurrentPage(1);

    payload = { ...payload, search: searchValue };

    //checking if the api for fetching users is already running to prevent duplicating the api Call
    if (userTypeToFetch !== 'super_agent' && usersStatus !== 'loading') {
      dispatch(
        getUsersRequest({
          path: `/sort-by-status?status=${userTypeToFetch}`,
          filter: { ...payload },
          per_page: pageSize,
          page: 1,
        }),
      );
    }

    //checking if the api for fetching users is already running to prevent duplicating the api Call
    if (userTypeToFetch === 'super_agent' && superAgentsStatus !== 'loading') {
      dispatch(
        getSuperAgentsRequest({
          filter: { ...payload },
          per_page: pageSize,
          page: 1,
        }),
      );
    }
  };

  // filter and export methods
  const handleExportFile = (type: string) => {
    setCurrentPage(1);
    const path =
      searchValue?.length >= 1 ? `?term=${searchValue}&include=account` : `/sort-by-status?status=${userTypeToFetch}`;
    const pathSuperAgent = searchValue?.length >= 1 ? `?term=${searchValue}&include=account` : `/super-agents?`;

    const downloadPath = userTypeToFetch === 'super_agent' ? pathSuperAgent : path;

    let exportPayload = {
      ...filterOptions,
      path: downloadPath,
      status: userTypeToFetch,
      export: type,
      page: 1,
      per_page: pageSize,
    };
    dispatch(downloadUsersRecord(exportPayload));
    setShowExportOptions(false);
  };

  const resetAllFilterOptions = () => {
    setFilterOptions(initialValues);

    setSearchValue('');

    setCurrentPage(1);

    setKycFilterLevelState([
      { value: 'level_zero', label: 'Level 0', isChecked: false, id: 1 },
      { value: 'basic', label: 'Level 1', isChecked: false, id: 2 },
      { value: 'intermediate', label: 'Level 2', isChecked: false, id: 3 },
      { value: 'premium', label: 'Level 3', isChecked: false, id: 4 },
    ]);
    setShowKYCLevelFilter(false);

    setDateDeactivated([
      {
        id: 1,
        value: 'today',
        title: 'Today',
        isChecked: false,
      },
      {
        id: 2,
        value: 'yesterday',
        title: 'Yesterday',
        isChecked: false,
      },
      {
        id: 3,
        value: 'one_week',
        title: 'One week',
        isChecked: false,
      },
      {
        id: 4,
        value: 'thirty_days',
        title: '30 Days',
        isChecked: false,
      },
      {
        id: 5,
        value: 'six_months',
        title: '6 Months',
        isChecked: false,
      },
      {
        id: 6,
        value: 'custom',
        title: 'Custom',
        isChecked: false,
      },
    ]);
    setShowDateDeactivatedFilter(false);

    setDateOnboarded([
      {
        id: 1,
        value: 'today',
        title: 'Today',
        isChecked: false,
      },
      {
        id: 2,
        value: 'yesterday',
        title: 'Yesterday',
        isChecked: false,
      },
      {
        id: 3,
        value: 'one_week',
        title: 'One week',
        isChecked: false,
      },
      {
        id: 4,
        value: 'thirty_days',
        title: '30 Days',
        isChecked: false,
      },
      {
        id: 5,
        value: 'six_months',
        title: '6 Months',
        isChecked: false,
      },
      {
        id: 6,
        value: 'custom',
        title: 'Custom',
        isChecked: false,
      },
    ]);
    setShowDateOnboarded(false);

    setLastSeen([
      {
        id: 1,
        value: 'today',
        title: 'Today',
        isChecked: false,
      },
      {
        id: 2,
        value: 'yesterday',
        title: 'Yesterday',
        isChecked: false,
      },
      {
        id: 3,
        value: 'one_week',
        title: 'One week',
        isChecked: false,
      },
      {
        id: 4,
        value: 'thirty_days',
        title: '30 Days',
        isChecked: false,
      },
      {
        id: 5,
        value: 'six_months',
        title: '6 Months',
        isChecked: false,
      },
      {
        id: 6,
        value: 'custom',
        title: 'Custom',
        isChecked: false,
      },
    ]);
    setShowLastSeen(false);
  };

  //ALL USE EFFECTS
  useEffect(() => {
    delayedFunction(handleSearchUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    //reset all filters
    resetAllFilterOptions();

    //triggger the re-fetch of items for the table
    if (selectedUsersCard.id === 1) {
      dispatch(
        getUsersRequest({
          path: `/sort-by-status?status=active`,
          per_page: pageSize,
          page: 1,
        }),
      );
    }

    if (selectedUsersCard.id === 2) {
      dispatch(
        getSuperAgentsRequest({
          per_page: pageSize,
          page: 1,
        }),
      );
    }

    if (selectedUsersCard.id === 3) {
      dispatch(
        getUsersRequest({
          path: `/sort-by-status?status=inactive`,
          per_page: pageSize,
          page: 1,
        }),
      );
    }

    if (selectedUsersCard.id === 4) {
      dispatch(
        getPhoneVerificationRequest({
          per_page: pageSize,
          page: 1,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsersCard]);

  useEffect(() => {
    let payload = { ...filterOptions };

    if (selectedUsersCard.id === 1) {
      dispatch(
        getUsersRequest({
          path: `/sort-by-status?status=active`,
          page: currentPage,
          per_page: pageSize,
          filter: { ...payload },
          
        }),
      );
    }

    if (selectedUsersCard.id === 2) {
      dispatch(
        getSuperAgentsRequest({
          page: currentPage,
          per_page: pageSize,
          filter: { ...payload },

        }),
      );
    }

    if (selectedUsersCard.id === 3) {
      dispatch(
        getUsersRequest({
          path: `/sort-by-status?status=inactive`,
          page: currentPage,
          per_page: pageSize,
          filter: { ...payload },

        }),
      );
    }

    if (selectedUsersCard.id === 4) {
      dispatch(
        getPhoneVerificationRequest({
          page: currentPage,
          per_page: pageSize,
          filter: { ...payload },

        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (usersStatus === 'succeeded') {
      let userCountResult: any[] = [];

      userCountResult = [
        {
          id: 1,
          count: usersState?.data?.active_users_count,
          title: 'Active Users',
        },
        {
          id: 2,
          count: usersState?.data?.super_agent_count,
          title: 'Super Agents',
        },
        {
          id: 3,
          count: usersState?.data?.inactive_users_count,
          title: 'Inactive Users',
        },
        {
          id: 4,
          count: usersState?.data?.pending_email_phone_count,
          title: 'Pending Validations',
        },
      ];

      let updateUsersData: any[] = [];

      usersState?.data?.users?.data?.forEach((item: Dictionary, index: number) => {
        if (userTypeToFetch === inActiveUser) {
          updateUsersData.push({
            id: index + 1,
            userId: item?.id,
            name: item?.name !== null ? `${item?.name}` : 'N/A',
            walletNo: item?.account?.number ? item?.account?.number : 'N/A',
            phone: item?.telephone,
            kycLevel: item?.kyc_level ? capitalizeFirstLetter(item?.kyc_level) : 'N/A',
            dateDeactivated: !item?.updated_at ? 'Null' : dateFormat(item?.updated_at),
          });
        } else {
          updateUsersData.push({
            id: index + 1,
            userId: item?.id,
            name: item?.name !== null ? `${item?.name}` : 'N/A',
            walletNo: item?.account?.number ? item?.account?.number : 'N/A',
            phone: item?.telephone,
            kycLevel: item?.kyc_level ? capitalizeFirstLetter(item?.kyc_level) : 'N/A',
            lastSeen: !item?.last_login ? 'Null' : dateFormat(item?.last_login),
            dateOnboarded: !item?.created_at ? 'Null' : dateFormat(item?.created_at),
          });
        }
      });

      // this is because i want this to set just one time
      // if (firstMount === true) {
      setUserCountData(userCountResult);
      // }
      setUsersData(updateUsersData);
      // setFirstMount(false);
      const {
        meta: { last_page },
      } = usersState?.data?.users;

      setTotalPages(last_page);
    }
    setShowFilterOptions(false);
    setShowSortBy(false);
    setShowExportOptions(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersStatus, usersState]);

  useEffect(() => {
    if (superAgentsStatus === 'succeeded') {
      let updateUsersData: any[] = [];

      superAgentsState?.data?.users?.data?.forEach((item: Dictionary, index: number) => {
        updateUsersData.push({
          id: index + 1,
          userId: item?.id,
          name: item?.name !== null ? `${item?.name}` : 'N/A',
          walletNo: item?.account?.number ? item?.account?.number : 'N/A',
          phone: item?.telephone ? item?.telephone : 'N/A',
          subAgents: item?.sub_agents_count,
          lastSeen: !item?.last_login ? 'Null' : dateFormat(item?.last_login),
          dateOnboarded: !item?.created_at ? 'Null' : dateFormat(item?.created_at),
        });
      });

      setUsersDataSuperAgent(updateUsersData);

      const {
        meta: { last_page },
      } = superAgentsState?.data?.users;

      setTotalPagesSuperAgent(last_page);
    }
    setShowFilterOptions(false);
    setShowSortBy(false);
    setShowExportOptions(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superAgentsStatus, superAgentsState]);

  useEffect(() => {
    if (pendingStateStatus === 'succeeded') {
      let updateUsersData: any[] = [];

      let userCountResult: any[] = [];

      userCountResult = [
        {
          id: 1,
          count: usersState?.data?.active_users_count,
          title: 'Active Users',
        },
        {
          id: 2,
          count: usersState?.data?.super_agent_count,
          title: 'Super Agents',
        },
        {
          id: 3,
          count: usersState?.data?.inactive_users_count,
          title: 'Inactive Users',
        },
        {
          id: 4,
          count: usersState?.data?.pending_email_phone_count,
          title: 'Pending Validations',
        },
      ];

      pendingState?.data?.pending_verification?.data?.forEach((item: Dictionary, index: number) => {
        updateUsersData.push({
          id: index + 1,
          userId: item?.id,
          name: item?.name ?? 'N/A',
          phone: item?.telephone ?? 'N/A',
          email: item?.email ?? 'N/A',
          verificationType: item?.verification_type ?? 'N/A',
          reasonForChange: item?.reason_for_change ?? 'N/A',
          approvedBy: item?.approved_by ?? 'N/A',
          status: item?.status ?? 'N/A',
          createdAt: item?.created_at ?? 'N/A',
        });
      });

      if (firstMount === true) {
        setUserCountData(userCountResult);
      }
      setUsersDataPending(updateUsersData);
      setFirstMount(false);

      const {
        meta: { last_page },
      } = pendingState?.data?.pending_verification;

      setTotalPagesPending(last_page);
    }
    setShowFilterOptions(false);
    setShowSortBy(false);
    setShowExportOptions(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingStateStatus, pendingState]);

  //This only runs when component mounts
  useEffect(() => {
    if (selectedUsersCard.id === 1) {
      dispatch(
        getUsersRequest({
          path: `/sort-by-status?status=active`,
        }),
      );
    }

    if (selectedUsersCard.id === 3) {
      dispatch(
        getUsersRequest({
          path: `/sort-by-status?status=inactive`,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //This only runs when component mounts
  useEffect(() => {
    dispatch(getSuperAgentsRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //This only runs when component mounts
  useEffect(() => {
    if (selectedUsersCard.id === 4) {
      dispatch(getPhoneVerificationRequest({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (approveChangeVerificationStatus === 'succeeded') {
      setShowSuccessModal(true);
      setShowImageUploadModal(false);
    }
  }, [approveChangeVerificationStatus]);

  const handleSelectedItem = (item: any) => {
    setSelectedItem(item);
    setShowImageUploadModal(true);
  };

  const handleApproveVerification = (data: any) => {
    dispatch(approveChangeVerificationRequest({ data: { status: data?.status }, id: data?.selectedItem?.userId }));
    setApproveValidationStatus(data?.status);
  };

  const handleCloseApprove = () => {
    setShowSuccessModal(false);
    dispatch(getPhoneVerificationRequest({}));
    dispatch(approveChangeVerificationReset());
    setFirstMount(true);
    dispatch(
      getUsersRequest({
        path: `/sort-by-status?status=active`,
        per_page: pageSize,
        page: 1,
      }),
    );
  };

  const userTypeUrl =
    selectedUsersCard.id === 1
      ? `${CUSTOMERS}?via=active`
      : selectedUsersCard.id === 2
        ? `${CUSTOMERS}?via=superAgent`
        : selectedUsersCard.id === 3
          ? `${CUSTOMERS}?via=inactive`
          : selectedUsersCard.id === 4
            ? `${CUSTOMERS}?via=pending`
            : `${CUSTOMERS}`;

  return (
    <AppContainer navTitle="CUSTOMERS">
      <>
        <UserContainer>
          {(showFilterOptions || showSortBy || showExportOptions) && (
            <div
              className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
              onClick={() => {
                setShowFilterOptions(false);
                setShowSortBy(false);
                setShowExportOptions(false);
              }}></div>
          )}
          <UsersContainer>
            <CountInfo
              data={userCountData}
              setSelectedData={setSelectedUsersCard}
              loading={pendingStateStatus === 'loading' || usersStatus === 'loading'}
              initialSelectedCardIndex={initialSelectedCardIndex}
              selectedCard={selectedUsersCard}
              cardTypeUrl={userTypeUrl}
            />

            {userTypeToFetch !== 'pending' && (
              <div className="tw-flex tw-flex-col tw-gap-1 md:tw-gap-x-4 md:tw-flex-row md:tw-justify-end md:tw-items-center">
                <SearchContainer style={{ margin: '30px 0' }}>
                  <SearchInput
                    placeholder="Search by Name or email"
                    backgroundColor={'transparent'}
                    name="SearchValue"
                    value={searchValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchValue(e.target.value);
                      setFilterOptions({
                        ...filterOptions,
                        search: e.target.value,
                      });
                    }}
                    disabled={
                      (selectedUsersCard.id === 1 && usersStatus === 'loading') ||
                      (selectedUsersCard.id === 2 && usersStatus === 'loading') ||
                      (selectedUsersCard.id === 3 && usersStatus === 'loading') ||
                      (selectedUsersCard.id === 4 && usersStatus === 'loading')
                    }
                    withIcon={false}
                  />
                </SearchContainer>

                <div className="tw-flex tw-items-center tw-justify-end tw-gap-x-4 md:tw-justify-between md:tw-mr-4">
                  <FilterLabel
                    title="Filter Options"
                    icon={<icons.BsFilter />}
                    onClick={() => setShowFilterOptions(true)}>
                    {/* <span className='tw-absolute tw-hidden'>here</span> */}
                    <FilterCard
                      showFilter={showFilterOptions}
                      title="Filter Options"
                      onClick={() => handleSubmitFilter()}
                      extraClass="tw-w-[13rem]"
                    // handleClose={() => setShowFilterOptions(false)}
                    >
                      <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden">
                        {/* false filter kyc option but this works to be deleted later */}
                        {/* {userTypeToFetch !== 'super_agent' && (
                        <ZojaSelect
                          options={[
                            { value: '', label: 'KYC Level' },
                            { value: 'basic', label: 'Level 1' },
                            { value: 'intermediate', label: 'Level 2' },
                            { value: 'premium', label: 'Level 3' },
                          ]}
                          name="status"
                          showArrown={true}
                          setSelectedOption={selected => setFilterOptions({ ...filterOptions, kyc_level: selected })}
                          extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88] tw-mb-2"
                          arrowDownClass="tw-right-lg tw-top-3.5"
                        />
                      )} */}

                        {/* filter for inactive and active => kyc level */}
                        {userTypeToFetch !== 'super_agent' && userTypeToFetch !== 'pending' && (
                          <FilterColapsible
                            title={`KYC Level`}
                            show={showKYCLevelFilter}
                            onClick={() => setShowKYCLevelFilter(!showKYCLevelFilter)}
                            extraClass="tw-shadow-none">
                            <>
                              <div className="">
                                {kycFilterLevelState.map(item => (
                                  <div
                                    key={`kycFilterLevelState${item.id}`}
                                    className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                    <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.label}</span>
                                    <ZojaCheckbox
                                      isChecked={item.isChecked}
                                      onClick={() => {
                                        handleSelectedOption(item, kycFilterLevelState, setKycFilterLevelState);
                                        setFilterOptions({
                                          ...filterOptions,
                                          kyc_level: item.isChecked ? item.value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            </>
                          </FilterColapsible>
                        )}

                        {/* filter for inactive => date deactivated */}
                        {userTypeToFetch === 'inactive' && (
                          <FilterColapsible
                            title={`Date Deactivated`}
                            show={showDateDeactivatedFilter}
                            onClick={() => setShowDateDeactivatedFilter(!showDateDeactivatedFilter)}
                            extraClass="tw-shadow-none">
                            <>
                              <div className="">
                                {dateDeactivated.map(item => (
                                  <div
                                    key={`dateDeactivated${item.id}`}
                                    className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                    <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                    <ZojaCheckbox
                                      isChecked={item.isChecked}
                                      onClick={() => {
                                        handleSelectedOption(item, dateDeactivated, setDateDeactivated);
                                        setFilterOptions({
                                          ...filterOptions,
                                          deactivated_at: item.isChecked ? item.value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                              <div
                                className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${filterOptions.date_onboarded === 'custom' && 'tw-max-h-fit'
                                  }`}>
                                <div className="tw-mb-2">
                                  <CustomDatePicker
                                    label="State Date"
                                    value={new Date()}
                                    setValue={value =>
                                      setOnboardedDateRange({ ...onboardedDateRange, state_date: value })
                                    }
                                  />
                                </div>
                                <div className="tw-mb-2">
                                  <CustomDatePicker
                                    label="End Date"
                                    value={new Date()}
                                    setValue={value =>
                                      setOnboardedDateRange({ ...onboardedDateRange, end_date: value })
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          </FilterColapsible>
                        )}

                        {/* filter for active and Super Agents => date onboarded */}
                        {userTypeToFetch !== 'inactive' && userTypeToFetch !== 'pending' && (
                          <FilterColapsible
                            title={`Date Onboarded`}
                            show={showDateOnboarded}
                            onClick={() => setShowDateOnboarded(!showDateOnboarded)}
                            extraClass="tw-shadow-none">
                            <>
                              <div className="">
                                {dateOnboarded.map((item: any) => (
                                  <div
                                    key={`dateOnboarded${item.id}`}
                                    className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                    <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                    <ZojaCheckbox
                                      isChecked={item.isChecked}
                                      onClick={() => {
                                        handleSelectedOption(item, dateOnboarded, setDateOnboarded);
                                        setFilterOptions({
                                          ...filterOptions,
                                          date_onboarded: item.isChecked ? item.value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                              <div
                                className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${filterOptions.date_onboarded === 'custom' && 'tw-max-h-fit'
                                  }`}>
                                <div className="tw-mb-2">
                                  <CustomDatePicker
                                    label="State Date"
                                    value={new Date()}
                                    setValue={value =>
                                      setOnboardedDateRange({ ...onboardedDateRange, state_date: value })
                                    }
                                  />
                                </div>
                                <div className="tw-mb-2">
                                  <CustomDatePicker
                                    label="End Date"
                                    value={new Date()}
                                    setValue={value =>
                                      setOnboardedDateRange({ ...onboardedDateRange, end_date: value })
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          </FilterColapsible>
                        )}

                        {/* filter for active and Super Agents => date onboarded */}
                        {userTypeToFetch !== 'inactive' && userTypeToFetch !== 'pending' && (
                          <FilterColapsible
                            title="Last Seen"
                            show={showLastSeen}
                            onClick={() => setShowLastSeen(!showLastSeen)}
                            extraClass="tw-shadow-none">
                            <>
                              <div className="">
                                {lastSeen.map((item: any) => (
                                  <div
                                    key={`lastSeen${item.id}`}
                                    className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                    <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                    <ZojaCheckbox
                                      isChecked={item.isChecked}
                                      onClick={() => {
                                        handleSelectedOption(item, lastSeen, setLastSeen);
                                        setFilterOptions({
                                          ...filterOptions,
                                          last_seen: item.isChecked ? item.value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>

                              <div
                                className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${filterOptions.last_seen === 'custom' && 'tw-max-h-fit'
                                  }`}>
                                <div className="tw-mb-2">
                                  <CustomDatePicker
                                    label="State Date"
                                    value={new Date()}
                                    setValue={value =>
                                      setLastSeenDateRange({ ...lastSeenDateRange, state_date: value })
                                    }
                                  />
                                </div>
                                <div className="tw-mb-2">
                                  <CustomDatePicker
                                    label="End Date"
                                    value={new Date()}
                                    setValue={value => setLastSeenDateRange({ ...lastSeenDateRange, end_date: value })}
                                  />
                                </div>
                              </div>
                            </>
                          </FilterColapsible>
                        )}

                        {/* filter for pending validations => kyc level */}
                        {userTypeToFetch === 'pending' && (
                          <FilterColapsible
                            title={`Verification Entry`}
                            show={verificationEntryFilter}
                            onClick={() => setVerificationEntryFilter(!verificationEntryFilter)}
                            extraClass="tw-shadow-none">
                            <>
                              <div className="">
                                {verificationEntryState.map(item => (
                                  <div
                                    key={`verificationEntryState${item.id}`}
                                    className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                    <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.label}</span>
                                    <ZojaCheckbox
                                      isChecked={item.isChecked}
                                      onClick={() => {
                                        handleSelectedOption(item, verificationEntryState, setVerificationEntryState);
                                        setFilterOptions({
                                          ...filterOptions,
                                          verification_entry: item.isChecked ? item.value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            </>
                          </FilterColapsible>
                        )}

                        {/* filter for pending validations => date range */}
                        {userTypeToFetch === 'pending' && (
                          <FilterColapsible
                            title="Date Range"
                            show={showDateRangeFilter}
                            onClick={() => setShowDateRangeFilter(!showDateRangeFilter)}
                            extraClass="tw-shadow-none">
                            <>
                              <div className="">
                                {dateRange.map((item: any) => (
                                  <div
                                    key={`dateRange${item.id}`}
                                    className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                    <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                    <ZojaCheckbox
                                      isChecked={item.isChecked}
                                      onClick={() => {
                                        handleSelectedOption(item, dateRange, setDateRange);
                                        setFilterOptions({
                                          ...filterOptions,
                                          date_range: item.isChecked ? item.value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>

                              <div
                                className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${filterOptions.last_seen === 'custom' && 'tw-max-h-fit'
                                  }`}>
                                <div className="tw-mb-2">
                                  <CustomDatePicker
                                    label="State Date"
                                    value={new Date()}
                                    setValue={value =>
                                      setLastSeenDateRange({ ...lastSeenDateRange, state_date: value })
                                    }
                                  />
                                </div>
                                <div className="tw-mb-2">
                                  <CustomDatePicker
                                    label="End Date"
                                    value={new Date()}
                                    setValue={value => setLastSeenDateRange({ ...lastSeenDateRange, end_date: value })}
                                  />
                                </div>
                              </div>
                            </>
                          </FilterColapsible>
                        )}
                      </div>
                    </FilterCard>
                  </FilterLabel>

                  <FilterLabel
                    title="Sort By"
                    icon={<icons.TbArrowsSort color="#5E6366" />}
                    onClick={() => setShowSortBy(true)}>
                    <FilterCard
                      showFilter={showSortBy}
                      title="Sort by"
                      onClick={() => handleSubmitFilter()}
                      submitButtonText="Apply"
                      extraClass="tw-w-[10rem] -tw-left-28">
                      <>
                        <div className="tw-pt-2">
                          {sortBy.map((item: any) => (
                            <div
                              key={`sortBy${item.id}`}
                              className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                              <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                              <ZojaCheckbox
                                isChecked={item.isChecked}
                                onClick={() => {
                                  handleSelectedOption(item, sortBy, setSortBy)
                                  setFilterOptions({
                                    ...filterOptions,
                                    sort_by: item.isChecked ? item.value : '',
                                  });
                                }
                                }
                              />
                            </div>
                          ))}
                        </div>
                        <div className="tw-pt-2 tw-border-">
                          {orderBy.map((item: any) => (
                            <div
                              key={`orderBy${item.id}`}
                              className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                              <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                              <ZojaCheckbox
                                isChecked={item.isChecked}
                                onClick={() => {
                                  handleSelectedOption(item, orderBy, setOrderBy)
                                  setFilterOptions({
                                    ...filterOptions,
                                    order_by: item.isChecked ? item.value : '',
                                  });
                                }
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    </FilterCard>
                  </FilterLabel>

                  <ExportLabel
                    title="Export"
                    onClick={() => setShowExportOptions(true)}
                    loading={downloadStatus === 'loading' ? true : false}>
                    <FilterCard
                      showFilter={showExportOptions}
                      title="Export as"
                      onClick={() => null}
                      showSubmitButton={false}
                      extraClass="tw-w-[10rem] -tw-left-28">
                      <div className="tw-flex tw-flex-col tw-mt-4">
                        <span
                          className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                          onClick={() => handleExportFile('csv')}>
                          CSV
                        </span>
                      </div>
                    </FilterCard>
                  </ExportLabel>
                </div>
              </div>
            )}

            <TableContainer>
              {selectedUsersCard.id === 1 &&
                (usersStatus === 'loading' ? (
                  <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                    <Oval
                      height="80"
                      width="80"
                      color="#222b88cf"
                      ariaLabel="tail-spin-loading"
                      secondaryColor="#222b882b"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : usersStatus === 'failed' ? (
                  <>
                    <div>There was an Error... Kindly Reload the page...</div>
                  </>
                ) : (
                  <CustomersUsersTable
                    type="active"
                    headerData={activeUsersTableHeaders}
                    header={true}
                    data={usersData}
                    onClick={item => navigate(`${CUSTOMERDETAILS}${item.userId}?type=${activeUserSearchType}`)}
                    sorting={handleSortItems}
                    order={order}
                  />
                ))}
              {selectedUsersCard.id === 2 &&
                (superAgentsStatus === 'loading' ? (
                  <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                    <Oval
                      height="80"
                      width="80"
                      color="#222b88cf"
                      ariaLabel="tail-spin-loading"
                      secondaryColor="#222b882b"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : superAgentsStatus === 'failed' ? (
                  <>
                    <div>There was an Error... Kindly Reload the page...</div>
                  </>
                ) : (
                  <CustomersUsersTable
                    type="subagents"
                    headerData={superAgentsTableHeaders}
                    header={true}
                    data={usersDataSuperAgent}
                    onClick={item => navigate(`${CUSTOMERDETAILS}${item.userId}?type=${superAgentUserSearchType}`)}
                    sorting={handleSuperAgentSort}
                    order={superAgentOrder}
                  />
                ))}
              {selectedUsersCard.id === 3 &&
                (usersStatus === 'loading' ? (
                  <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                    <Oval
                      height="80"
                      width="80"
                      color="#222b88cf"
                      ariaLabel="tail-spin-loading"
                      secondaryColor="#222b882b"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : usersStatus === 'failed' ? (
                  <>
                    <div>There was an Error... Kindly Reload the page...</div>
                  </>
                ) : (
                  <CustomersUsersTable
                    type="inactive"
                    headerData={inActiveUsersTableHeaders}
                    header={true}
                    data={usersData}
                    onClick={item => navigate(`${CUSTOMERDETAILS}${item.userId}?type=${inactiveUserSearchType}`)}
                    sorting={handleSortItems}
                    order={order}
                  />
                ))}
              {selectedUsersCard.id === 4 &&
                (pendingStateStatus === 'loading' ? (
                  <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                    <Oval
                      height="80"
                      width="80"
                      color="#222b88cf"
                      ariaLabel="tail-spin-loading"
                      secondaryColor="#222b882b"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : pendingStateStatus === 'failed' ? (
                  <>
                    <div>There was an Error... Kindly Reload the page...</div>
                  </>
                ) : (
                  <div className="tw-mt-6">
                    <CustomersUsersTable
                      type="pending"
                      headerData={pendingVerificationTableHeaders}
                      header={true}
                      data={usersDataPending}
                      onClick={item => handleSelectedItem(item)}
                      sorting={handleSortItems}
                      order={order}
                    />
                  </div>
                ))}

              <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
                {/* PAGINATION FOR ACTIVE and INACTIVE USERS */}
                {usersStatus === 'failed' ? (
                  <></>
                ) : (
                  selectedUsersCard.id !== 2 &&
                  selectedUsersCard.id !== 4 &&
                  usersData.length >= 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={selectedPage => {
                        setCurrentPage(selectedPage);
                      }}
                      isLoading={usersStatus === 'loading'}
                    />
                  )
                )}

                {/* PAGINATION FOR SUPER AGENT USERS */}
                {superAgentsStatus === 'failed' ? (
                  <></>
                ) : (
                  selectedUsersCard.id === 2 &&
                  usersDataSuperAgent.length >= 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPagesSuperAgent}
                      onPageChange={selectedPage => {
                        setCurrentPage(selectedPage);
                      }}
                      isLoading={superAgentsStatus === 'loading'}
                    />
                  )
                )}

                {/* PAGINATION FOR PENDING VERIFICATION USERS */}
                {pendingStateStatus === 'failed' ? (
                  <></>
                ) : (
                  selectedUsersCard.id === 4 &&
                  usersDataPending.length >= 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPagesPending}
                      onPageChange={selectedPage => {
                        setCurrentPage(selectedPage);
                      }}
                      isLoading={pendingStateStatus === 'loading'}
                    />
                  )
                )}

                {/* PAGE SIZE CONTROL FOR ACTIVE and INACTIVE USERS */}
                {usersStatus === 'loading' || usersStatus === 'failed' ? (
                  <></>
                ) : (
                  selectedUsersCard.id !== 2 &&
                  selectedUsersCard.id !== 4 &&
                  usersData.length >= 1 && (
                    <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                      <span className="tw-text-xs tw-w-16">Per page</span>
                      <ZojaSelect
                        options={[
                          { label: '10', value: '10' },
                          { label: '20', value: '20' },
                          { label: '50', value: '50' },
                          { label: '100', value: '100' },
                          { label: '200', value: '200' },
                        ]}
                        value={`${pageSize}`}
                        // value={usersState?.data?.users?.meta?.per_page}
                        // value={`24`}
                        setSelectedOption={selected => handlePageSizeChange(selected)}
                        extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                      />
                    </div>
                  )
                )}

                {/* PAGE SIZE CONTROL FOR SUPER AGENT USERS */}
                {superAgentsStatus === 'loading' || superAgentsStatus === 'failed' ? (
                  <></>
                ) : (
                  selectedUsersCard.id === 2 &&
                  usersDataSuperAgent.length >= 1 && (
                    <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                      <span className="tw-text-xs tw-w-16">Per page</span>
                      <ZojaSelect
                        options={[
                          { label: '10', value: '10' },
                          { label: '20', value: '20' },
                          { label: '50', value: '50' },
                          { label: '100', value: '100' },
                          { label: '200', value: '200' },
                        ]}
                        value={`${pageSize}`}
                        // value={superAgentsState?.data?.users?.meta?.per_page}
                        // value={`24`}
                        setSelectedOption={selected => handlePageSizeChange(selected)}
                        extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                      />
                    </div>
                  )
                )}

                {/* PAGE SIZE CONTROL FOR PENDING */}
                {pendingStateStatus === 'loading' || pendingStateStatus === 'failed' ? (
                  <></>
                ) : (
                  selectedUsersCard.id === 4 &&
                  usersDataPending.length >= 1 && (
                    <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                      <span className="tw-text-xs tw-w-16">Per page</span>
                      <ZojaSelect
                        options={[
                          { label: '10', value: '10' },
                          { label: '20', value: '20' },
                          { label: '50', value: '50' },
                          { label: '100', value: '100' },
                          { label: '200', value: '200' },
                        ]}
                        value={`${pageSize}`}
                        // value={superAgentsState?.data?.users?.meta?.per_page}
                        // value={`24`}
                        setSelectedOption={selected => handlePageSizeChange(selected)}
                        extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                      />
                    </div>
                  )
                )}
              </div>
            </TableContainer>
          </UsersContainer>
        </UserContainer>
        <ImageUploadModal
          show={showImageUploadModal}
          bvnImage={sample_image}
          uploadImage={sample_image}
          handleClose={() => setShowImageUploadModal(false)}
          buttoText="Finish"
          loading={approveChangeVerificationStatus === 'loading'}
          handleApprove={(item: string) => handleApproveVerification(item)}
          selectedItem={selectedItem}
          approveValidationStatus={approveValidationStatus}
        />

        <ZojaModal
          show={showSuccessModal}
          handleClose={() => {
            setShowSuccessModal(false);
          }}
          height="auto"
          position="center"
          extraClass="md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
          borderRadius=".5rem">
          <div className="tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4">
            <div className=" tw-w-full tw-mb-8 tw-flex tw-justify-center">
              <img src={approveValidationStatus === 'approved' ? images.check : images.reject} alt="" />
            </div>

            <span className="tw-block tw-text-center tw-text-[#6A616F] tw-text-sm tw-mb-10">
              Document Upload has been successfully {approveValidationStatus === 'approved' ? 'approved' : 'rejected'}
            </span>

            <span
              className="tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer"
              onClick={() => {
                setShowSuccessModal(false);
              }}>
              <icons.MdClose />
            </span>
            <div className="my-3">
              <ZojaButton
                onClick={() => handleCloseApprove()}
                text="Close"
                extraClass="tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-44 tw-mx-auto tw-p-2 tw-mt-4 hover:tw-bg-isPrimary/90 disabled:tw-bg-blue-900"
              />
            </div>
          </div>
        </ZojaModal>
      </>
    </AppContainer>
  );
}

export default Customers;
