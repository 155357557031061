import { useEffect, useRef, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  ActivityActionModal,
  AppContainer,
  CreateInternalUserModal,
  LoginHistoryOnlyModal,
  MoreIconView,
  ProfileActivationToggleModal,
} from '../../atoms';
import {
  CustomDatePicker,
  DownloadSuccessModal,
  ExportLabel,
  FilterCard,
  FilterColapsible,
  FilterLabel,
  InternaUsersTable,
  NavTab,
  Pagination,
  PaymentEmptyState,
  SearchInput,
  ZojaCheckbox
} from '../../components';
import {
  arrayToString,
  capitalizeFirstLetter,
  colors,
  dateFormat,
  formatStartAndEndDateWithDelimeter,
  getSelectedOptionValue,
  getWordFromString,
  handleSelectedOption,
  icons,
  images,
  lowerCaseFirstLetter,
  routesPath,
  timeFormat,
  useDelayedFunction
} from '../../utils';
import { InternalUserTop, InternalUsersContainer, TableContainer, UserContainer } from './style';

import { Oval } from 'react-loader-spinner';
import { ZojaButton, ZojaSelect } from '../../components/tailwind';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  createInternalUserRequest,
  createInternalUserReset,
  downloadInternalUserRecord,
  downloadInternalUserRecordReset,
  downloadRolePermissionReset,
  getInternalUsersRequest,
  getLoginHistoryRequest,
  getRolesDropDownRequest,
  getRolesRequest,
  resetInternalUserPasswordRequest,
  resetInternalUserPasswordReset,
  updateInternalUserRequest,
  updateInternalUserReset,
  updateUserStatusRequest,
  updateUserStatusReset
} from '../../redux/slice';
import { Dictionary } from '../../types';
import { CustomUseSortTable } from '../../utils/hooks/useSortTable';
import { UserPageTabs, internalUsersDataHeader, loginHistoryDataHeader } from './data';

const { USERROLES } =
  routesPath;

interface Permission {
  id: number;
  name: string;
  description: string | null;
}

interface PermissionsWithChecked {
  [label: string]: Permission[];
}

const activeUser = 'active';
const inActiveUser = 'inactive';
const superAgentUser = 'super_agent';

const namedEdit = 'Edit';
const namedDeactivate = 'Deactivate';
const namedReactivate = 'Reactivate';
const namedResetPassword = 'Reset Password';
const namedViewLoginHistory = 'View Login History';

const roleDetails = 'Role Details';
const roleDeleteRole = 'Delete Role';

const userTypeToFetchByActivity = (data: Dictionary) => {
  let result: string = '';
  if (!data?.hasOwnProperty('id') || data?.id === 1) {
    result = activeUser;
  }

  if (data?.id === 2) {
    result = superAgentUser;
  }

  if (data?.id === 3) {
    result = inActiveUser;
  }

  return result;
};

//Table more dropdown
const dropdownList: Dictionary = [
  {
    id: 1,
    title: 'Edit',
    value: 'edit',
  },
  {
    id: 2,
    title: 'Deactivate',
    value: 'deactivate',
  },
  {
    id: 3,
    title: 'Reactivate',
    value: 'reactivate',
  },
  {
    id: 4,
    title: 'Reset Password',
    value: 'resetPassword',
  },
];

const sortByOptions = {
  aphabetically: [
    {
      id: 1,
      value: 'az',
      title: 'A-Z',
      isChecked: false,
    },
    {
      id: 2,
      value: 'za',
      title: 'Z-A',
      isChecked: false,
    },
    {
      id: 3,
      value: 'kyc_level',
      title: 'Kyc Level',
      isChecked: false,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
    {
      id: 3,
      value: 'last_seen',
      title: 'Last Seen',
      isChecked: false,
    },
  ],
};

const memberAndLastSeenOptions = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

const LastSeenOptions = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

const initialValues: Dictionary = {
  status: '',
  role: '',
  kyc_level: '',
  last_seen: '',
  sort_by: '',
  order_by: '',
  date_onboarded: '',
  deactivated_at: '',
  search: '',
};

function Users() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const successModalRef = useRef<any>(null);

  const [selectedUsersCard, setSelectedUsersCard] = useState<Dictionary>({
    id: 1,
    count: 0,
    title: 'Active Users',
  });

  const [searchValue, setSearchValue] = useState('');
  const [selectedInternalUserItem, setSelectedInternalUserItem] = useState<Dictionary>({});
  const [selectedRoleItem, setSelectedRoleItem] = useState<Dictionary>({});
  const [moreIconIsVisible, setMoreIconIsVisible] = useState(false);
  const [roleMoreIconIsVisible, setRoleMoreIconIsVisible] = useState(false);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [rolePermissionTotalPages, setRolePermissionTotalPages] = useState(5);
  const [internalUserTotalPages, setInternalUserTotalPages] = useState(5);
  const [, setSelectedTransactionActionText] = useState('');
  const [, setSelectedRoleActionText] = useState('');
  const [searchInternalUserValue, setSearchInternalUserValue] = useState('');
  const [perPage, setPerPage] = useState<any>('10');
  const [, setInternalUsersData] = useState<any[]>([]);
  const [internalUsersDataList, setInternalUsersDataList] = useState<any[]>([]);
  const [createInternalUserIsModalVisible, setCreateInternalUserIsModalVisible] = useState(false);
  const [editInternalUserIsModalVisible, setEditInternalUserIsModalVisible] = useState(false);
  const [openPermissions, setOpenPermissions] = useState(false);
  const [userRole, setUserRole] = useState();
  const [rolesData, setRolesData] = useState([
    {
      value: 'admin',
      label: 'Admin',
    },
  ]);
  const [userRolesData, setUserRolesData] = useState([
    {
      value: 'admin',
      label: 'Admin',
    },
  ]);
  const [rolesPermissionData, setRolesPermissionData] = useState([
    {
      value: '',
      label: '',
      permissionCount: '',
      permissions: [],
      module_name: ''
    },
  ]);

  const [permissionData, setPermissionData] = useState({});

  const [createUserSuccessModalVisible, setCreateUserSuccessModalVisible] = useState(false);
  const [toggleGetInternalUser, setToggleGetInternalUser] = useState(false);
  const [resetPasswordSuccessModalVisible, setResetPasswordSuccessModalVisible] = useState(false);
  const [profileActivationSuccessIsModalVisible, setProfileActivationSuccessIsModalVisible] = useState(false);
  const [profileActivationIsModalVisible, setProfileActivationIsModalVisible] = useState(false);
  const [userAccountStatus, setUserAccountStatus] = useState('');
  const [deactiveMessage, setDeactiveMessage] = useState('');
  const [loginHistoryIsModalVisible, setLoginHistoryIsModalVisible] = useState(false);
  const [loginHistoryData, setLoginHistoryData] = useState<any[]>([]);
  // const [, setUserRolesData] = useState<any[]>([]);
  const [userRolesDataList, setUserRolesDataList] = useState<any[]>([]);
  const [deleteRoleIsModalVisible, setDeleteRoleIsModalVisible] = useState(false);
  const [deleteRoleSuccessIsModalVisible, setDeleteRoleSuccessIsModalVisible] = useState(false);
  const [order, setOrder] = useState('ASC');

  // export states
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showSortBy, setShowSortBy] = useState(false);
  const [showDateOnboarded, setShowDateOnboarded] = useState(false);
  const [showLastSeen, setShowLastSeen] = useState(false);
  const [activePageTab, setActivePageTab] = useState('internal_users');
  const [sortBy, setSortBy] = useState(sortByOptions.aphabetically);
  const [dateOnboarded, setDateOnboarded] = useState(memberAndLastSeenOptions);
  const [lastSeen, setLastSeen] = useState(LastSeenOptions);
  const [orderBy, setOrderBy] = useState(sortByOptions.orderBy);
  const [onboardedDateRange, setOnboardedDateRange] = useState({ state_date: '', end_date: '' });
  const [lastSeenDateRange, setLastSeenDateRange] = useState({ state_date: '', end_date: '' });
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);
  const [currentUserData, setCurrentUserData] = useState<any>([]);
  const [isSort, setIsSort] = useState<any>(false);
  const [sortHeader, setSortHeader] = useState<any>('');
  const [isNextPage, setIsNextPage] = useState<any>(false);

  const decideUserCurrentStatus: string = userAccountStatus === 'active' ? namedDeactivate : namedReactivate;
  //More Icon for Internal Users
  const moreIconOption = [namedEdit, decideUserCurrentStatus, namedResetPassword, namedViewLoginHistory];
  const roleMoreIconOption = [roleDetails, roleDeleteRole];

  const loginState = useAppSelector(state => state.login);

  // redux state
  // const usersState = useAppSelector(state => state.getUsers);
  // const { status: usersStatus } = usersState;

  const superAgentsState = useAppSelector(state => state.getSuperAgents);
  const { status: superAgentsStatus } = superAgentsState;

  const internalUsersState = useAppSelector(state => state.getInternalUsers);
  const { status: internalUsersStatus } = internalUsersState;

  const rolesDropDownState = useAppSelector(state => state.getRolesDropDown);
  const { status: rolesDropDownStatus } = rolesDropDownState;

  const createInternalUserState = useAppSelector(state => state.createInternalUser);
  const { status: createInternalUserStatus } = createInternalUserState;

  const updateInternalUserState = useAppSelector(state => state.updateInternalUser);
  const { status: updateInternalUserStatus } = updateInternalUserState;

  const resetInternalUserPasswordState = useAppSelector(state => state.resetInternalUserPassword);
  const { status: resetInternalUserPasswordStatus } = resetInternalUserPasswordState;

  const updateUserStatusState = useAppSelector(state => state.updateUserStatus);
  const { status: updateUserStatusStatus } = updateUserStatusState;

  const loginHistoryState = useAppSelector(state => state.getLoginHistory);
  const { status: loginHistoryStatus } = loginHistoryState;

  const rolesState = useAppSelector(state => state.getRolesDropDown);
  const { status: rolesStatus } = rolesState;

  // download records status states
  const { status: downloadStatus } = useAppSelector(state => state.downloadUsersRecord);
  const { status: downloadInternalUserStatus } = useAppSelector(state => state.downloadInternalUserRecord);

  // get users by status
  const userTypeToFetch = userTypeToFetchByActivity(selectedUsersCard);

  useEffect(() => {
    // removing kyc and adding status to sort by option
    setSortBy((prevState: any) => {
      let newOption = {
        id: prevState.length + 1,
        value: 'status',
        title: 'Status',
        isChecked: false,
      };
      let prevData = sortByOptions.aphabetically.filter((f: any) => f.value !== 'kyc_level');
      prevData = [...prevData, { ...newOption }];
      return prevData;
    });

    const getSelectedSortBy = getSelectedOptionValue(sortBy);
    const getSelectedOrderBy = getSelectedOptionValue(orderBy);

    const onboarded_date_range = formatStartAndEndDateWithDelimeter(
      onboardedDateRange.state_date,
      onboardedDateRange.end_date,
    );

    const lastseen_date_range = formatStartAndEndDateWithDelimeter(
      lastSeenDateRange.state_date,
      lastSeenDateRange.end_date,
    );

    let payload = { ...filterOptions };
    payload = payload.date_onboarded === 'custom' ? { ...payload, date_onboarded: onboarded_date_range } : payload;
    payload = payload.last_seen === 'custom' ? { ...payload, last_seen: lastseen_date_range } : payload;

    dispatch(
      getInternalUsersRequest({
        ...payload,
        status: '',
        sort_by: getSelectedSortBy,
        order_by: getSelectedOrderBy,
        search: '',
        page: currentPage,
        per_page: perPage,
      }),
    );
  }, [activePageTab, toggleGetInternalUser, updateUserStatusState, currentPage]);

  useEffect(() => {
    dispatch(getRolesDropDownRequest({}));
  }, []);

  useEffect(() => {
    dispatch(
      getRolesRequest({
        last_seen: '',
        role_creator: '',
        sort_by: '',
        order_by: '',
        date_range: '',
        term: '',
        page: currentPage,
        per_page: perPage,
      }),
    );
    setIsSort(false);
  }, []);

  useEffect(() => {
    if (internalUsersStatus === 'succeeded') {
      let updateUsersData: any[] = [];
      internalUsersState?.data?.users?.data?.forEach((item: Dictionary, index: number) => {
        updateUsersData.push({
          id: index + 1,
          name: item?.name || 'N/A',
          email: item?.email,
          role: arrayToString(item?.roles),
          status: item?.status,
          lastSeen: item?.last_login,
          dateEnrolled: item?.created_at,
          userId: item?.id,
          permissions: item?.permissions,
        });
      });

      const {
        meta: { last_page },
      } = internalUsersState?.data?.users;

      setInternalUserTotalPages(last_page);
      setInternalUsersData(updateUsersData);
      setInternalUsersDataList(updateUsersData);
    }
  }, [internalUsersState]);

  useEffect(() => {
    if (rolesDropDownStatus === 'succeeded') {
      let updateRoleDta: any[] = [];
      if (!editInternalUserIsModalVisible) {
        updateRoleDta.push({ label: 'Select Role', value: '' });
      }
      rolesDropDownState?.data?.roles?.data?.forEach((item: Dictionary) => {
        updateRoleDta.push({
          value: item.name,
          label: capitalizeFirstLetter(item.name),
        });
      });
      setRolesData(updateRoleDta);
    }
  }, [rolesDropDownState]);

  useEffect(() => {
    if (rolesDropDownStatus === 'succeeded') {
      let updateRoleDta: any[] = [];
      if (!editInternalUserIsModalVisible) {
        updateRoleDta.push({ label: 'Role', value: '' });
      }
      rolesDropDownState?.data?.roles?.data?.forEach((item: Dictionary) => {
        updateRoleDta.push({
          value: item.name,
          label: capitalizeFirstLetter(item.name),
        });
      });
      setUserRolesData(updateRoleDta);
    }
  }, [rolesDropDownState]);

  useEffect(() => {
    if (rolesStatus === 'succeeded') {
      let updateRolesData: any[] = [];
      rolesState?.data?.roles?.data?.forEach((item: Dictionary, idx: number) => {
        let roleName = item.name
        const permissions = item?.admin_modules?.map((d: any) => {
          return {
            id: d.id,
            module_name: d.name,
            permissions: d.permissions
          }
        })
        updateRolesData.push({
          value: roleName,
          label: roleName,
          permissionCount: null,
          permissions_data: permissions,
        })
      });
      updateRolesData = updateRolesData?.map((item: any) => {
        return {
          ...item, permissions_data: item?.permissions_data?.map((d: any) => {
            return {
              ...d, permissions: d.permissions.map((data: any) => {
                return {
                  ...data,
                  isChecked: false,
                  value: data.name,
                  label: data.name,
                }
              })
            }
          })
        }
      })

      setRolesPermissionData(updateRolesData);
    }
  }, [rolesState]);

  useEffect(() => {
    setPermissionData(rolesPermissionData);
  }, [rolesPermissionData, openPermissions]);

  useEffect(() => {
    let resultLoginHistory: any[] = [];
    if (loginHistoryStatus === 'succeeded') {
      loginHistoryState?.data?.users?.data?.forEach((el: Dictionary, index: number) => {
        resultLoginHistory.push({
          id: index + 1,
          time: `${dateFormat(el?.login_at)} - ${timeFormat(el?.login_at)}`,
          device: el?.data?.userAgent === null ? 'N/A' : el?.data?.userAgent,
        });
      });

      setLoginHistoryData(resultLoginHistory);
    }
  }, [loginHistoryState]);

  // successful deactivate or reactivate user
  useEffect(() => {
    if (updateUserStatusStatus === 'succeeded') {
      setProfileActivationSuccessIsModalVisible(true);
    }
  }, [updateUserStatusState]);

  useEffect(() => {
    if (createInternalUserStatus === 'succeeded') {
      setCreateInternalUserIsModalVisible(false);
      setCreateUserSuccessModalVisible(true);
    }
    if (updateInternalUserStatus === 'succeeded') {
      let payload = { key: 'permissions', value: currentUserData };
      setEditInternalUserIsModalVisible(false);
      setCreateUserSuccessModalVisible(true);
    }
  }, [createInternalUserState, updateInternalUserState]);

  useEffect(() => {
    if (resetInternalUserPasswordStatus === 'succeeded') {
      setResetPasswordSuccessModalVisible(true);
    }
  }, [resetInternalUserPasswordState]);

  useEffect(() => {
    let resultRoles: any[] = [];
    if (rolesStatus === 'succeeded') {
      rolesState?.data?.roles?.data?.forEach((el: Dictionary, index: number) => {
        resultRoles.push({
          title: el.name,
          permissionCount: el?.permission_count,
          userCount: el?.user_count,
          createdBy: el?.publisher === null ? 'N/A' : el?.publisher,
          roleId: el?.id,
          id: el?.id,
          dateCreated: el?.created_at,
        });
      });

      const last_page = rolesState?.data?.roles?.meta?.last_page;

      setRolePermissionTotalPages(last_page);
      setUserRolesDataList(resultRoles);
    }
  }, [rolesState]);

  // handle different modules
  const handleMoreIconOptions = async (item: any) => {
    setMoreIconIsVisible(false);
    setUserAccountStatus(item?.title === 'Reactivate' ? 'inactive' : 'active');
    if (item.title === namedEdit) {
      dispatch(getRolesDropDownRequest({}));
      setEditInternalUserIsModalVisible(true);
    }
    if (item.title === namedDeactivate || item.title === namedReactivate) {
      setProfileActivationIsModalVisible(true);
    }
    if (item.title === namedResetPassword) {
      dispatch(resetInternalUserPasswordRequest({ userId: selectedInternalUserItem?.userId }));
    }
    if (item.title === namedViewLoginHistory) {
      setLoginHistoryIsModalVisible(true);
      dispatch(getLoginHistoryRequest({ userId: selectedInternalUserItem?.userId }));
    }
  };

  // getRolesDropDownRequest
  const handleRoleMoreIconOptions = (item: any) => {
    setRoleMoreIconIsVisible(false);
    if (item.title === roleDetails) {
      navigate(`${USERROLES}${selectedRoleItem?.id.toString()}`);
    }

    if (item.title === roleDeleteRole) {
      setDeleteRoleIsModalVisible(true);
    }
  };

  const addInternalUserBtn = () => {
    dispatch(getRolesDropDownRequest({}));
    setCreateInternalUserIsModalVisible(true);
  };

  const handleCreateInternalUserModalBtn = (item: Dictionary) => {
    const { email, department_id, role, permissions } = item;
    const payload = {
      role,
      email,
      permissions,
      department_id
    };
    dispatch(createInternalUserRequest(payload));
  };

  const handleCloseCreateInternalUserModal = () => {
    setCreateUserSuccessModalVisible(false);
    dispatch(createInternalUserReset());
    dispatch(updateInternalUserReset());
    setToggleGetInternalUser(!toggleGetInternalUser);
  };

  const handleEditInternalUserModalBtn = (item: Dictionary) => {
    const { email, name, role, permissions, department_id } = item;

    

    const payload = {
      name,
      role: lowerCaseFirstLetter(role),
      email,
      userId: selectedInternalUserItem?.userId,
      permissions,
      department_id
    };

    // console.log(payload)

    const filteredPermissions = rolesState?.data?.roles?.data?.filter(
      (f: Dictionary) => f.name.toLowerCase() === payload.role.toLowerCase(),
    )[0]?.permissions;

    dispatch(updateInternalUserRequest(payload));
  };

  const handleCloseResetPasswordModal = () => {
    setResetPasswordSuccessModalVisible(false);
    dispatch(resetInternalUserPasswordReset());
  };

  // reactivate or deactivate user action
  const handleUserProfileActivity = () => {
    let payload: Dictionary;
    if (userAccountStatus === 'active') {
      payload = {
        userId: selectedInternalUserItem?.userId,
        data: {
          status: 'inactive',
          comment: deactiveMessage,
        },
      };
    } else {
      payload = {
        userId: selectedInternalUserItem?.userId,
        data: {
          status: 'active',
        },
      };
    }

    dispatch(updateUserStatusRequest(payload));
  };

  const handleProfileActivationSuccessClose = () => {
    setProfileActivationSuccessIsModalVisible(false);
    dispatch(updateUserStatusReset());
  };

  const handleOnchangeInternalUser = (value: any) => {
    setSearchInternalUserValue(value);
  };

  // filter and export methods
  const handleExportInternalUserFile = (type: string) => {
    let exportPayload = { ...filterOptions, export: type, search: searchInternalUserValue };
    dispatch(downloadInternalUserRecord(exportPayload));
    setShowExportOptions(false);
  };

  // filter table handler

  const handleSubmitInternalUserFilter = () => {
    const onboarded_date_range = formatStartAndEndDateWithDelimeter(
      onboardedDateRange.state_date,
      onboardedDateRange.end_date,
    );
    const lastseen_date_range = formatStartAndEndDateWithDelimeter(
      lastSeenDateRange.state_date,
      lastSeenDateRange.end_date,
    );
    let payload = { ...filterOptions };
    payload = payload.date_onboarded === 'custom' ? { ...payload, date_onboarded: onboarded_date_range } : payload;
    payload = payload.last_seen === 'custom' ? { ...payload, last_seen: lastseen_date_range } : payload;
    dispatch(
      getInternalUsersRequest({ ...payload, search: searchInternalUserValue, per_page: perPage, page: currentPage }),
    );
  };

  // on check get onboarded value
  useEffect(() => {
    const getOnboardedvalue = getSelectedOptionValue(dateOnboarded);
    const getLastSeenValue = getSelectedOptionValue(lastSeen);
    const getSelectedSortBy = getSelectedOptionValue(sortBy);
    const getSelectedOrderBy = getSelectedOptionValue(orderBy);

    // userTypeToFetch !== 'inactive'
    let onboarded_data = getOnboardedvalue;
    let deactivated_data = userTypeToFetch === 'inactive' ? '' : getOnboardedvalue;

    setFilterOptions({
      ...filterOptions,
      date_onboarded: onboarded_data,
      deactivated_at: deactivated_data,
      last_seen: getLastSeenValue,
      sort_by: getSelectedSortBy,
      order_by: getSelectedOrderBy,
    });
  }, [dateOnboarded, lastSeen, sortBy, orderBy, selectedUsersCard]);

  // show download success modal
  useEffect(() => {
    if (downloadStatus === 'succeeded' || downloadInternalUserStatus === 'succeeded') {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadStatus, downloadInternalUserStatus]);

  // fetch role and permission by term on input change
  const delay = 1000;
  const { delayedFunction } = useDelayedFunction(delay);

  useEffect(() => {
    if (searchInternalUserValue.length >= 2 || searchInternalUserValue.length === 0) {
      delayedFunction(handleSubmitInternalUserFilter);
    }
  }, [searchInternalUserValue]);

  useEffect(() => {
    setShowFilterOptions(false);
    setShowSortBy(false);
    setShowExportOptions(false);
  }, [internalUsersStatus, superAgentsStatus]);

  // handle page number change
  const handlePageNumberChange = (pageNumber: any, type: string) => {
    setPerPage(pageNumber);

    const onboarded_date_range = formatStartAndEndDateWithDelimeter(
      onboardedDateRange.state_date,
      onboardedDateRange.end_date,
    );

    let payload = { ...filterOptions };
    payload = payload.date_onboarded === 'custom' ? { ...payload, date_onboarded: onboarded_date_range } : payload;

    if (type === 'user') {
      dispatch(
        getInternalUsersRequest({
          ...payload,
          search: searchValue,
          page: currentPage,
          per_page: pageNumber,
        }),
      );
    }
  };

  // Custom Hooks
  const { CustomHandleSortItems } = CustomUseSortTable({
    order: order,
    setOrder,
    data: internalUsersDataList,
    setData: setInternalUsersDataList,
    isNextPage: isNextPage,
  });

  return (
    <AppContainer navTitle="USER">
      <>
        <DownloadSuccessModal
          show={showSuccessDownloadModal}
          handleClose={() => {
            // dispatch(downloadUsersRecordReset());
            dispatch(downloadInternalUserRecordReset());
            dispatch(downloadRolePermissionReset());
            setShowSuccessDownloadModal(false);
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Item Downloaded"
          content="Your document has been downloaded to your device"
        />
        <UserContainer>
          {(showFilterOptions || showSortBy || showExportOptions) && (
            <div
              className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
              onClick={() => {
                setShowFilterOptions(false);
                setShowSortBy(false);
                setShowExportOptions(false);
              }}></div>
          )}
          <NavTab navList={UserPageTabs} selected={activePageTab} setSelected={setActivePageTab} />

          <InternalUsersContainer>
            <InternalUserTop className="tw-flex tw-flex-col lg:tw-flex-row tw-items-start tw-justify-between lg:tw-items-center tw-gap-y-2">
              <ZojaButton
                extraClass={`tw-bg-isPrimary tw-font-thin tw-text-white tw-text-[14px] tw-p-2.5 tw-px-4 tw-rounded-[4px]`}
                text={'New User'}
                icon={<AiOutlinePlus color={colors.white} size={15} />}
                onClick={addInternalUserBtn}
              />
              <div className="tw-flex tw-items-center tw-justify-end tw-gap-x-4 md:tw-justify-between md:tw-mr-4">
                <SearchInput
                  backgroundColor={'transparent'}
                  name="searchProfileValue"
                  value={searchInternalUserValue}
                  onChange={(e: any) => handleOnchangeInternalUser(e.target.value)}
                  placeholder="Search by name"
                />
                <FilterLabel
                  title="Filter Options"
                  icon={<icons.BsFilter />}
                  onClick={() => setShowFilterOptions(true)}>
                  <FilterCard
                    showFilter={showFilterOptions}
                    title="Filter Options"
                    onClick={() => handleSubmitInternalUserFilter()}
                    extraClass="tw-w-[13rem]">
                    <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden">
                      <ZojaSelect
                        options={[
                          { value: '', label: 'Status' },
                          { value: 'active', label: 'active' },
                          { value: 'inactive', label: 'inactive' },
                        ]}
                        name="status"
                        showArrown={true}
                        setSelectedOption={selected => setFilterOptions({ ...filterOptions, status: selected })}
                        extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88] tw-mb-2"
                        arrowDownClass="tw-right-lg tw-top-3.5"
                      />
                      <ZojaSelect
                        options={userRolesData}
                        name="role"
                        showArrown={true}
                        setSelectedOption={selected => setFilterOptions({ ...filterOptions, role: selected })}
                        extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88] tw-mb-2"
                        arrowDownClass="tw-right-lg tw-top-3.5"
                      />
                      <FilterColapsible
                        title="Date Onboarded"
                        show={showDateOnboarded}
                        onClick={() => setShowDateOnboarded(!showDateOnboarded)}
                        extraClass="tw-shadow-none">
                        <>
                          <div className="">
                            {dateOnboarded.map((item: any) => (
                              <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                <ZojaCheckbox
                                  isChecked={item.isChecked}
                                  onClick={() => handleSelectedOption(item, dateOnboarded, setDateOnboarded)}
                                />
                              </div>
                            ))}
                          </div>
                          <div
                            className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${filterOptions.date_onboarded === 'custom' && 'tw-max-h-fit'
                              }`}>
                            <div className="tw-mb-2">
                              <CustomDatePicker
                                label="State Date"
                                value={new Date()}
                                setValue={value => setOnboardedDateRange({ ...onboardedDateRange, state_date: value })}
                              />
                            </div>
                            <div className="tw-mb-2">
                              <CustomDatePicker
                                label="End Date"
                                value={new Date()}
                                setValue={value => setOnboardedDateRange({ ...onboardedDateRange, end_date: value })}
                              />
                            </div>
                          </div>
                        </>
                      </FilterColapsible>

                      <FilterColapsible
                        title="Last Seen"
                        show={showLastSeen}
                        onClick={() => setShowLastSeen(!showLastSeen)}
                        extraClass="tw-shadow-none">
                        <>
                          <div className="">
                            {lastSeen.map((item: any) => (
                              <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                <ZojaCheckbox
                                  isChecked={item.isChecked}
                                  onClick={() => handleSelectedOption(item, lastSeen, setLastSeen)}
                                />
                              </div>
                            ))}
                          </div>

                          <div
                            className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${filterOptions.last_seen === 'custom' && 'tw-max-h-fit'
                              }`}>
                            <div className="tw-mb-2">
                              <CustomDatePicker
                                label="State Date"
                                value={new Date()}
                                setValue={value => setLastSeenDateRange({ ...lastSeenDateRange, state_date: value })}
                              />
                            </div>
                            <div className="tw-mb-2">
                              <CustomDatePicker
                                label="End Date"
                                value={new Date()}
                                setValue={value => setLastSeenDateRange({ ...lastSeenDateRange, end_date: value })}
                              />
                            </div>
                          </div>
                        </>
                      </FilterColapsible>
                    </div>
                  </FilterCard>
                </FilterLabel>

                <FilterLabel
                  title="Sort By"
                  icon={<icons.TbArrowsSort color="#5E6366" />}
                  onClick={() => setShowSortBy(true)}>
                  <FilterCard
                    showFilter={showSortBy}
                    title="Sort by"
                    onClick={() => handleSubmitInternalUserFilter()}
                    submitButtonText="Apply"
                    extraClass="tw-w-[10rem] -tw-left-28">
                    <>
                      <div className="tw-pt-2">
                        {sortBy.map((item: any) => (
                          <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                            <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                            <ZojaCheckbox
                              isChecked={item.isChecked}
                              onClick={() => handleSelectedOption(item, sortBy, setSortBy)}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="tw-pt-2 tw-border-">
                        {orderBy.map((item: any) => (
                          <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                            <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                            <ZojaCheckbox
                              isChecked={item.isChecked}
                              onClick={() => handleSelectedOption(item, orderBy, setOrderBy)}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  </FilterCard>
                </FilterLabel>

                <ExportLabel
                  title="Export"
                  onClick={() => setShowExportOptions(true)}
                  loading={downloadInternalUserStatus === 'loading' ? true : false}>
                  <FilterCard
                    showFilter={showExportOptions}
                    title="Export as"
                    onClick={() => null}
                    showSubmitButton={false}
                    extraClass="tw-w-[10rem] -tw-left-28">
                    <div className="tw-flex tw-flex-col tw-mt-4">
                      <span
                        className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                        onClick={() => handleExportInternalUserFile('pdf')}>
                        PDF
                      </span>
                      <span
                        className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                        onClick={() => handleExportInternalUserFile('csv')}>
                        CSV
                      </span>
                    </div>
                  </FilterCard>
                </ExportLabel>
              </div>
            </InternalUserTop>
            {internalUsersStatus === 'loading' ? (
              <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                <Oval
                  height="80"
                  width="80"
                  color="#222b88cf"
                  ariaLabel="tail-spin-loading"
                  secondaryColor="#222b882b"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <TableContainer>
                {internalUsersDataList.length >= 1 ? (
                  <InternaUsersTable
                    data={internalUsersDataList}
                    setSelectedItem={setSelectedInternalUserItem}
                    selectedItem={selectedInternalUserItem}
                    tableColumns={internalUsersDataHeader}
                    dropdownList={dropdownList}
                    sorting={CustomHandleSortItems}
                    setUserAccountStatus={setUserAccountStatus}
                    order={order}
                    setOrder={setOrder}
                    handleMoreIconOptions={handleMoreIconOptions}
                    setIsSort={setIsSort}
                    setSortHeader={setSortHeader}
                    setIsNextPage={setIsNextPage}
                  />
                ) : (
                  <PaymentEmptyState text="No user found" containerClass="tw-h-[50vh]" imageWidth={200} />
                )}
                {internalUsersDataList.length >= 1 && (
                  <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={internalUserTotalPages}
                      onPageChange={selectedPage => {
                        setCurrentPage(selectedPage);
                        setIsNextPage(true);
                        setIsSort(true);
                      }}
                    />
                    <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                      <span className="tw-text-xs tw-w-16">Per page</span>
                      <ZojaSelect
                        options={[
                          { label: '10', value: '10' },
                          { label: '20', value: '20' },
                          { label: '50', value: '50' },
                          { label: '100', value: '100' },
                          { label: '200', value: '200' },
                        ]}
                        value={internalUsersState?.data?.users?.meta?.per_page}
                        setSelectedOption={selected => handlePageNumberChange(selected, 'user')}
                        extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                      />
                    </div>
                  </div>
                )}
              </TableContainer>
            )}
          </InternalUsersContainer>
          {/* )} */}

          <MoreIconView
            setSelectedText={setSelectedTransactionActionText}
            isModalVisible={moreIconIsVisible}
            closeModal={() => setMoreIconIsVisible(false)}
            options={moreIconOption}
            onClick={item => handleMoreIconOptions(item)}
          />
          <MoreIconView
            setSelectedText={setSelectedRoleActionText}
            isModalVisible={roleMoreIconIsVisible}
            closeModal={() => setRoleMoreIconIsVisible(false)}
            options={roleMoreIconOption}
            onClick={item => handleRoleMoreIconOptions(item)}
          />

          <CreateInternalUserModal
            isModalVisible={createInternalUserIsModalVisible}
            editing={false}
            closeModal={() => {
              setCreateInternalUserIsModalVisible(false);
              setOpenPermissions(false);
            }}
            title="Add New User"
            setUserRole={setUserRole}
            openPermissions={openPermissions}
            setOpenPermissions={setOpenPermissions}
            permissionDefault={permissionData}
            permissions={permissionData}
            setPermissions={setPermissionData}
            setCurrentUserData={setCurrentUserData}
            isLoading={rolesDropDownStatus === 'loading'}
            isSubmittingInternalUser={createInternalUserStatus === 'loading'}
            isSuccessInternaluser={createInternalUserStatus === 'succeeded'}
            onSubmit={(item: Dictionary) => handleCreateInternalUserModalBtn(item)}
            roleOption={rolesData}
            actionBtnText={'Create User'}
            defaultValues={{ defaultEmail: '', defaultName: '', defaultRole: '' }}
          />

          {/* edit internal user modal */}
          <CreateInternalUserModal
            isModalVisible={editInternalUserIsModalVisible}
            editing={editInternalUserIsModalVisible}
            closeModal={() => {
              setEditInternalUserIsModalVisible(false);
              setOpenPermissions(false);
            }}
            title="Edit User Details"
            setUserRole={setUserRole}
            permissionDefault={permissionData}
            permissions={permissionData}
            setPermissions={setPermissionData}
            setCurrentUserData={setCurrentUserData}
            openPermissions={openPermissions}
            rolesAndPermissions={rolesState?.data?.roles?.data}
            setOpenPermissions={setOpenPermissions}
            isSuccessInternaluser={updateInternalUserStatus === 'succeeded'}
            isLoading={rolesDropDownStatus === 'loading'}
            isSubmittingInternalUser={updateInternalUserStatus === 'loading'}
            onSubmit={(item: Dictionary) => handleEditInternalUserModalBtn(item)}
            roleOption={rolesData}
            actionBtnText={'Update'}
            defaultValues={{
              defaultEmail: selectedInternalUserItem.hasOwnProperty('email') ? selectedInternalUserItem.email : '',
              defaultName: selectedInternalUserItem.hasOwnProperty('name')
                ? getWordFromString(selectedInternalUserItem.name, 1)
                : '',
              defaultRole: selectedInternalUserItem.hasOwnProperty('role') ? selectedInternalUserItem.role : '',
              defaultPermissions: selectedInternalUserItem.hasOwnProperty('permissions')
                ? selectedInternalUserItem.permissions
                : '',
                defaultId: selectedInternalUserItem.hasOwnProperty('userId')
                ? selectedInternalUserItem.userId
                : '',
            }}
          />

          <ActivityActionModal
            isModalVisible={createUserSuccessModalVisible}
            closeModal={handleCloseCreateInternalUserModal}
            title={
              updateInternalUserStatus === 'succeeded'
                ? 'User Details Successfully Updated'
                : 'User Successfully Created'
            }
            text={updateInternalUserStatus === 'succeeded' ? '' : 'An Onboarding mail has been sent to the user'}
            actionText="Close"
            image={updateInternalUserStatus === 'succeeded' ? images.check : images.sent}
            actionClick={handleCloseCreateInternalUserModal}
            isLoading={false}
          />

          {/* reset password success modal */}
          <ActivityActionModal
            isModalVisible={resetPasswordSuccessModalVisible}
            closeModal={handleCloseResetPasswordModal}
            title={''}
            text={'Password Reset link has been sent to the User'}
            actionText="Close"
            image={images.sent}
            actionClick={handleCloseResetPasswordModal}
            isLoading={false}
          />
          {/* activate or deactive user */}
          <ProfileActivationToggleModal
            isModalVisible={profileActivationIsModalVisible}
            activityStatus={userAccountStatus}
            text={
              userAccountStatus === 'active'
                ? 'Please Input reason for deactivation'
                : 'Are you sure you want to reactivate this user`s profile?'
            }
            actionClicked={handleUserProfileActivity}
            closeModal={() => setProfileActivationIsModalVisible(false)}
            setDeactiveMessage={setDeactiveMessage}
          />

          {/* this modal shows when admin successfully activate or deactivate a user */}
          <ActivityActionModal
            isModalVisible={profileActivationSuccessIsModalVisible}
            closeModal={handleProfileActivationSuccessClose}
            actionClick={handleProfileActivationSuccessClose}
            image={images.check}
            isLoading={false}
            actionText="Close"
            title=""
            text={
              userAccountStatus === 'active'
                ? 'Profile has been successfuly deactivated'
                : 'Profile has been successfuly reactivated'
            }
          />

          {/* this modal shows login history */}
          <LoginHistoryOnlyModal
            actionClick={() => { }}
            closeModal={() => setLoginHistoryIsModalVisible(false)}
            isModalVisible={loginHistoryIsModalVisible}
            title="Login History"
            data={loginHistoryData}
            headerData1={loginHistoryDataHeader}
            isLoading={loginHistoryStatus === 'loading'}
          />

          {/* this modal is to delete user */}
        </UserContainer>
      </>
    </AppContainer>
  );
}

export default Users;
