import Cookies from 'js-cookie';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';
import { authRequest } from './authSlice';
import { routesPath, showMessage } from '../../utils';
export interface UserResetType {
  email: string;
  password: string;
  token: any;
  name: string;
  password_confirmation:string;
}
type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

const { TOKEN, REMEMBERUSER } = routesPath;

export const userPasswordResetRequest = createAsyncThunk('user-password-reset', async (payload: UserResetType, { dispatch }) => {
  const { email, password,password_confirmation, token, name } = payload;
  try {
    // for the actual login api

    const response = await api.post('admin/onboarding/password/reset', payload);

    const { token, user } = response.data;
    dispatch(authRequest(user));

    return response?.data;
  } catch (err) {
    throw err;
  }
});

export const resendToken = createAsyncThunk('token-resend', async (payload: any, { dispatch }) => {
  try {
    // for the actual login api

    const response = await api.post('admin/onboarding/password/reset/resend', {email:payload});

    const { token, user } = response.data;
    showMessage({
      type: 'success',
      message: "Token resent successfully"
    })

    return response?.data;
  } catch (err) {
    throw err;
  }
});

const PasswordResetSlice = createSlice({
  name: 'user-password-reset',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
    updateUserPasswordResetData: (state, action) => {
      state.data.user = { ...state.data.user, [action.payload.key]: action.payload.value };
    },
  },
  extraReducers: builder => {
    builder.addCase(userPasswordResetRequest.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(userPasswordResetRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(userPasswordResetRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const UserPasswordReset = PasswordResetSlice.actions.reset;
export const updateUserPasswordResetData = PasswordResetSlice.actions.updateUserPasswordResetData;
export const UserPasswordResetSliceReducer = PasswordResetSlice.reducer;
