import { BarChart2, ChevronRight } from 'lucide-react';
// import Image from "next/image";
// import Link from "next/link";
// import { usePathname, useRouter } from "next/navigation";
import { useEffect, useMemo, useState } from 'react';
// import { BiPackage, BiShoppingBag, BiTag } from 'react-icons/bi';
// import { BsPlusSquare, BsTruck } from 'react-icons/bs';
import { FaHandHoldingUsd, FaUsers } from 'react-icons/fa';
import { FiMail, FiSettings, FiUser, FiUsers } from 'react-icons/fi';
import { TbLayoutDashboard } from 'react-icons/tb';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ZojaButton } from '../../components/tailwind';
import { cn } from '../../lib/utils';
import { images, routesPath, useWindowResize } from '../../utils';
import { getStorageItem, removeStorageItem, setStorageItem } from '../../utils/hooks/useLocalStorage';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { MdAccountBox } from 'react-icons/md';
import { ReactComponent as ReportIconDark } from '../../assets/svg/reportIconDark.svg';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { logoutRequest } from '../../redux/slice';
// import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

interface IProps {
  showSidebar: any;
  setShowSidebar: (state: any) => any;
}

const {
  DASHBOARD,
  SUPPORT,
  KYC,
  PENDINGKYC,
  VERIFIEDKYC,
  REJECTEDKYC,
  FREEZEREQUESTS,
  RECONCILIATION,
  SETTLEMENTS,
  CASHREQUESTINFORMATION,
  USERS,
  ACCOUNTMANAGEMENT,
  REPORT,
  TRANSACTIONS,
  PAYMENTS,
  SINGLE_PAYMENTS,
  INITIATE_SINGLE_PAYMENTS,
  SETTINGS,
  CUSTOMERS,
  BANUNBAN,
  RESTRICTIONACCOUNTS,
  RESTRICTIONREQUESTS,
  RESTRICTIONS,
  TRANSACTION_APPROVALS
} = routesPath;

const navigationPath = [
  {
    id: crypto.randomUUID().toString(),
    title: 'Main',
    links: [
      {
        id: crypto.randomUUID().toString(),
        title: 'Dashboard',
        accessor: '/dashboard',
        icon: <TbLayoutDashboard className="w-4 h-4" />,
        path: DASHBOARD,
        collapsed: false,
        subLinks: [],
      },
    ],
  },
  {
    id: crypto.randomUUID().toString(),
    title: 'Customer',
    links: [
      // {
      //   id: crypto.randomUUID().toString(),
      //   title: 'Kyc',
      //   accessor: 'kyc',
      //   icon: <FiUsers className="w-4 h-4" />,
      //   path: KYC,
      //   collapsed: false,
      //   subLinks: [],
      // },
      {
        id: crypto.randomUUID().toString(),
        title: 'Kyc',
        accessor: 'kyc',
        icon: <FiUsers className="w-4 h-4" />,
        path: PENDINGKYC,
        collapsed: false,
        subLinks: [],
      },
    ],
  },
  {
    id: crypto.randomUUID().toString(),
    title: 'Help',
    links: [
      {
        id: crypto.randomUUID().toString(),
        title: 'Support',
        accessor: 'Support',
        icon: <FiMail className="w-4 h-4" />,
        path: SUPPORT,
        collapsed: false,
        subLinks: [],
      },
    ],
  },
  {
    id: crypto.randomUUID().toString(),
    title: 'Settlements',
    links: [
      {
        id: crypto.randomUUID().toString(),
        title: 'Settlements',
        accessor: 'Settlements',
        icon: <AiOutlineDollarCircle className="w-4 h-4" />,
        path: '#Settlements',
        collapsed: false,
        subLinks: [
          {
            id: crypto.randomUUID().toString(),
            title: 'Settlements',
            accessor: 'Settlements',
            path: SETTLEMENTS,
          },
          {
            id: crypto.randomUUID().toString(),
            title: 'Reconciliation',
            accessor: 'Reconciliation',
            path: RECONCILIATION,
          },
        ],
      },
    ],
  },
  {
    id: crypto.randomUUID().toString(),
    title: 'Cash',
    links: [
      {
        id: crypto.randomUUID().toString(),
        title: 'Cash Request',
        accessor: 'Cash Request',
        icon: <FaHandHoldingUsd className="w-4 h-4" />,
        path: CASHREQUESTINFORMATION,
        collapsed: false,
        subLinks: [],
      },
    ],
  },
  {
    id: crypto.randomUUID().toString(),
    title: 'Customers',
    links: [
      {
        id: crypto.randomUUID().toString(),
        title: 'Customers',
        accessor: 'Customers',
        icon: <FaUsers className="w-4 h-4" />,
        path: CUSTOMERS,
        collapsed: false,
        subLinks: [],
      },
    ],
  },
  {
    id: crypto.randomUUID().toString(),
    title: 'Users',
    links: [
      {
        id: crypto.randomUUID().toString(),
        title: 'Users',
        accessor: 'Users',
        icon: <FiUser className="w-4 h-4" />,
        path: USERS,
        collapsed: false,
        subLinks: [],
      },
    ],
  },
  {
    id: crypto.randomUUID().toString(),
    title: 'Account',
    links: [
      {
        id: crypto.randomUUID().toString(),
        title: 'Account',
        accessor: 'Account',
        icon: <MdAccountBox className="w-4 h-4" />,
        path: '#accountMgt',
        collapsed: false,
        subLinks: [
          {
            id: crypto.randomUUID().toString(),
            title: 'Account Mgt',
            accessor: 'Account Mgt',
            path: ACCOUNTMANAGEMENT,
          },
          {
            id: crypto.randomUUID().toString(),
            title: 'Freeze/Unfreeze',
            accessor: 'Ban/Unban',
            path: FREEZEREQUESTS,
          },
          {
            id: crypto.randomUUID().toString(),
            title: 'Ban/Unban',
            accessor: 'Ban/Unban',
            path: BANUNBAN,
          },
          {
            id: crypto.randomUUID().toString(),
            title: 'Account Restriction',
            accessor: 'Account Restriction',
            path: RESTRICTIONS,
          },
        ],
      },
    ],
  },
  {
    id: crypto.randomUUID().toString(),
    title: 'Report',
    links: [
      {
        id: crypto.randomUUID().toString(),
        title: 'Report',
        accessor: 'Report',
        icon: <ReportIconDark className="w-4 h-4" />,
        path: REPORT,
        collapsed: false,
        subLinks: [],
      },
    ],
  },

  {
    id: crypto.randomUUID().toString(),
    title: 'Transactions',
    links: [
      {
        id: crypto.randomUUID().toString(),
        title: 'Transactions',
        icon: <BarChart2 className="w-4 h-4" />,
        path: '#Transactions',
        accessor: 'Transactions',
        collapsed: false,
        subLinks: [
          {
            id: crypto.randomUUID().toString(),
            title: 'Transactions',
            accessor: 'Transactions',
            path: TRANSACTIONS,
          },
          {
            id: crypto.randomUUID().toString(),
            title: 'Payments',
            accessor: 'Payments',
            path: SINGLE_PAYMENTS,
          },
          {
            id: crypto.randomUUID().toString(),
            title: 'Approvals',
            accessor: 'Approvals',
            path: `${TRANSACTION_APPROVALS}?view=step-1&ui=pending`,
        },
        ],
      },
    ],
  },
  {
    id: crypto.randomUUID().toString(),
    title: 'Settings',
    links: [
      {
        id: crypto.randomUUID().toString(),
        title: 'Settings',
        accessor: 'settings',
        icon: <FiSettings className="w-4 h-4" />,
        path: SETTINGS,
        collapsed: false,
        subLinks: [],
      },
    ],
  },
];

const Sidebar = ({ setShowSidebar, showSidebar }: IProps) => {
  const collapsibleData = getStorageItem('collapsible');
  const activeNavData = getStorageItem('activeNav');

  const [navigationState, setNavigationState] = useState(navigationPath);
  const [collapsible, setCollapsible] = useState({ path: '/dashboard', collapsed: false });
  const [activeNav, setActiveNav] = useState('dashboard');

  const toggleCollapsed = (linkId: string) => {
    const currentState = [...navigationState];
    let newState = currentState.map(data => {
      let links = data.links.map(link => {
        if (link.id !== linkId) {
          link.collapsed = false;
        }

        return link.id === linkId ? { ...link, collapsed: !link.collapsed } : link;
      });
      return { ...data, links: links };
    });
    setNavigationState(newState);
  };

  // console.log(navigationState)

  const NAVIGATION_MENU = useMemo(() => navigationState, [navigationState]);

  const { width } = useWindowResize();

  const { status } = useAppSelector(state => state.logout);
  const {
    data: { name },
  } = useAppSelector(state => state.auth);

  const normal = 'tw-relative tw-p-2.5 tw-block tw-flex tw-items-center tw-gap-x-2';
  const defaultLink = normal + ' tw-text-gray-500 tw-pl-4 hover:tw-text-isPrimary hover:tw-p-2.5 hover:tw-pl-4';
  const activeLink = normal + ' tw-text-white tw-bg-isPrimary tw-rounded-[4px] tw-pl-4 hover:tw-text-white';

  // const location = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logoutRequest());
  };

  useEffect(() => {
    if (width < 768) {
      // dispatch an action to close sidebar
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  // Set the default active link
  // If store has active link, set active link to store link
  useEffect(() => {
    if (collapsibleData && activeNavData) {
      setActiveNav(activeNavData);
      setCollapsible(collapsibleData);
      return;
    }
    setStorageItem('collapsible', collapsible);
    setStorageItem('activeNav', activeNav);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update store link to selected link
  useEffect(() => {
    setStorageItem('collapsible', collapsible);
    setStorageItem('activeNav', activeNav);
  }, [collapsible, activeNav]);

  useEffect(() => {
    if (status === 'succeeded') {
      removeStorageItem('collapsible');
      removeStorageItem('activeNav');
    }
  }, [status]);

  return (
    <>
      {showSidebar && width < 768 && (
        <div
          className="tw-absolute tw-w-screen tw-h-screen tw-left-0 tw-bg-neutral-50/5 tw-z-20 tw-cursor-pointer"
          onClick={() => setShowSidebar(!showSidebar)}></div>
      )}
      <nav
        className={`tw-fixed tw-top-0 tw-left-0 tw-h-screen tw-bg-white tw-w-[14rem] tw-pt-4 tw-z-30 tw-transition-all tw-duration-500 tw-ease-in-out tw-border- md:tw-relativ md:tw-x-7 lg:tw-w-[15.6rem] ${
          showSidebar || width >= 768 ? 'tw-translate-x-0' : '-tw-translate-x-full'
        }`}>
        <div className="tw-flex tw-justify-center tw-pb-4">
          <img src={images.logoMain} alt="logo" />
        </div>

        <section className="tw-mt-4 tw-h-[85vh] tw-flex tw-flex-col tw-justify-between">
          <div className="tw-flex-1 tw-overflow-y-scroll">
            {NAVIGATION_MENU?.map(navigation => (
              <div key={navigation.id} className="tw-relative tw-pb-2">
                {/* <span className="tw-block tw-font-extralight tw-pl-5 tw-pb-3 tw-text-sm">{navigation.title}</span> */}
                {navigation?.links?.map(link => (
                  <div className="tw-mx-7" key={link.id}>
                    <Link
                      key={link.id}
                      to={link?.path}
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setStorageItem('activeNav', link.accessor);
                        setStorageItem('collapsible', {
                          path: link.path,
                          collapsed: false,
                        });
                        setCollapsible({
                          ...collapsible,
                          path: link.path,
                          collapsed: false,
                        });
                        toggleCollapsed(link.id);
                        if (link.subLinks.length > 0) {
                          setCollapsible({
                            ...collapsible,
                            path: link.path,
                            collapsed: !collapsible.collapsed,
                          });
                          setActiveNav(link.accessor);
                          return;
                        }
                        setActiveNav(link.accessor);
                        setShowSidebar(false);
                        navigate(link.path);
                      }}
                      className={cn(
                        `tw-relative tw-no-underline tw-p-2.5 tw-items-center tw-gap-x-2`,
                        link?.path === collapsible?.path ||
                          (link.accessor === activeNav && link.path === collapsible?.path)
                          ? activeLink
                          : defaultLink,
                      )}>
                      <>
                        <span>{link.icon}</span>
                        <span className="tw-text-sm tw-font-extralight md:tw-text-[.95rem]">{link.title}</span>
                        {link.subLinks.length > 0 && (
                          <span>
                            <ChevronRight
                              className={cn(
                                `tw-h-3.5 tw-w-3.5 tw-absolute tw-right-2 tw-top-3.5 tw-text-gray-500 tw-transition-all tw-ease-in-out tw-duration-300`,
                                link.collapsed ? 'tw-rotate-90' : 'tw-rotate-0',
                                link.accessor === activeNav ? 'tw-text-white' : 'tw-text-gray-500',
                              )}
                            />
                          </span>
                        )}
                      </>
                      {(link?.path === collapsible?.path ||
                        (link.accessor === activeNav && link.path === collapsible?.path)) && (
                        <span className="tw-absolute tw-h-7 tw-w-1 tw-bg-isPrimary -tw-left-7"></span>
                      )}
                    </Link>

                    <div
                      className={cn(`tw-h-0 tw-transition-all tw-ease-in-out tw-duration-300 tw-overflow-hidden`)}
                      style={{ height: link.collapsed ? `${2.3 * link.subLinks.length}rem` : '0' }}>
                      <div className="tw-flex tw-flex-col tw-gap-y-3">
                        {link.subLinks.map(link => (
                          <div key={link.id} className={`first:tw-pt-2 last:tw-mb-3`}>
                            <Link
                              key={link.path}
                              to={link.path}
                              className={cn(
                                `tw-relative tw-font-extralight tw-pl-10 tw-text-sm tw-no-underline tw-text-gray-500 hover:tw-text-isPrimary`,
                                location.pathname === link.path && 'tw-text-isPrimary tw-font-black',
                              )}>
                              {link.title}
                              {
                                <span
                                  className={cn(
                                    'tw-absolute tw-h-1 tw-w-1 tw-rounded-full tw-bg-gray-400 tw-left-7 tw-top-1 tw-z-10',
                                    location.pathname === link.path && 'tw-bg-isPrimary',
                                  )}></span>
                              }
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
                <span className="tw-block tw-mt-2 tw-border-b tw-border-b-gray-50 tw-w-[82%] tw-mx-auto"></span>
              </div>
            ))}
          </div>

          <div className="tw-h-[30%] tw-mx-6 md:tw-mx-10 tw-rounded-xl tw-bg-white tw-flex tw-flex-col tw-items-center tw-pt-4 tw-shadow-zojaShadowSix">
            <img src={images.user} alt="" width={50} height={50} className=" tw-rounded-full" />
            <span className="tw-block tw-w-[8rem] tw-text-sm tw-text-center tw-mt-3 tw-text-isPrimary tw-font-bold tw-truncate">
              {name}
            </span>
            <span className="tw-text-[.6rem]">Super Admin</span>
            <ZojaButton
              extraClass={cn(
                'tw-font-normal tw-bg-background tw-bg-none tw-border tw-border-red-500 tw-h-7 tw-px-7 tw-rounded-[8px] tw-mt-6 tw-text-red-400 tw-text-[11px] hover:tw-bg-red-400 hover:tw-text-white hover:tw-border-white',
                status === 'loading' && 'disabled:tw-bg-red-500 disabled:tw-text-white',
              )}
              onClick={handleLogout}
              loading={status === 'loading'}
              disabled={status === 'loading'}
              text="Logout"
            />
          </div>
        </section>
      </nav>
    </>
  );
};

export default Sidebar;
