import { routesPath } from '../../utils';

export const loginHistoryDataHeader = {
  time: 'Time',
};

export const departmentDataHeader = [
  {
    id: 1,
    title: 'S/N',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Department Name',
    value: 'departmentName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'Team Count',
    value: 'teamCount',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Assigned Admin',
    value: 'assignedAdmin',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Department status',
    value: 'status',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Date Created',
    value: 'dateCreated',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: '',
    hasSortIcon: false,
  },
];

const { USERS, ROLESANDPERMISSIONS, DEPARTMENT, ADMIN_REQUEST } = routesPath;

export const UserPageTabs = [
  {
    id: 1,
    title: 'Internal Users',
    url: USERS,
  },
  {
    id: 2,
    title: 'Roles and permission',
    url: ROLESANDPERMISSIONS,
  },
  {
    id: 3,
    title: 'Departments',
    url: DEPARTMENT,
  },
  
];

export const departmentData = [
  {
    id: 1,
    departmentName: 'Finance',
    teamCount: '12',
    assignedAdmin: 'Contained Admin name',
    status: 'Pending',
    dateCreated: '12/22/2021',
  },
  {
    id: 2,
    departmentName: 'Technical',
    teamCount: '12',
    assignedAdmin: 'Contained Admin name',
    status: 'Active',
    dateCreated: '12/22/2021',
  },
  {
    id: 3,
    departmentName: 'Customer Success',
    teamCount: '12',
    assignedAdmin: 'Contained Admin name',
    status: 'Inactive',
    dateCreated: '12/22/2021',
  },
];
