import React from 'react';
import "./index.css"
const CustomToast: React.FC<{ closeToast?: () => void }> = ({ closeToast }) => {
    const getCurrentDate = () => {
        const today = new Date();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(today.getDate()).padStart(2, '0');
        const year = today.getFullYear();
      
        return `${month}-${day}-${year}`;
      };
      
    return (
        <div>
            <div className='custom-view-body'>
                <div className='font-weight-bold toast-heading pt-3'>Approval Required: Flagged Transaction</div>
                <div className='py-3 toast-body'>Hi Customer success, a transaction requires your approval</div>
                <div>{getCurrentDate()},  Just now</div>
            </div>
        </div>
    );
};

export default CustomToast
