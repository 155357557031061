import * as yup from 'yup';
import { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Container, ContainerContent, Content, SwitchCard } from '../login/style';
import { Button, Input, Switch } from '../../components';
import { colors, spacing } from '../../utils';
import { H2 } from '../../styles';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { userPasswordResetRequest, resendToken } from '../../redux/slice';
import { routesPath, showMessage } from '../../utils';
import Cookies from 'js-cookie';

const { DASHBOARD, REMEMBERUSER, TOKEN, LOGIN } = routesPath;
function Login() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [switchChecked, setSwitchChecked] = useState(false);
    const [rememberedUserEmail, setRememberedUserEmail] = useState('');
    const userPassworResetState = useAppSelector(state => state.userPasswordReset);


    const { status } = userPassworResetState;


    const schema = yup.object().shape({
        email: yup.string().email().required('Email is required'),
        password: yup.string().required('Password is required'),
        name: yup.string().required('Name is required'),
        token: yup.string().required('Token is required'),

    });


    useLayoutEffect(() => {
        const cookiesEmail = Cookies.get(REMEMBERUSER);

        if (typeof cookiesEmail === 'string' && cookiesEmail.length > 4) {
            setSwitchChecked(true);
            setRememberedUserEmail(cookiesEmail);
        } else {
            setSwitchChecked(false);
            setRememberedUserEmail('');
        }
    }, []);

    // using this or real api call
    useEffect(() => {
        if (status === 'succeeded') {

            setTimeout(() => {
                showMessage({
                    type: 'success',
                    message: "Password updated successfully,.. Redirecting to Login"
                })
                navigate(LOGIN);
            }, 2000)
        }
    }, [status]);

    return (
        <Formik
            initialValues={{ email: rememberedUserEmail, password: '', token: '', name: '' }}
            enableReinitialize={true}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
                const { email, password, name, token } = values;
                const payload = {
                    email: email.trim(),
                    password: password.trim(),
                    token: token.trim(),
                    name: name.trim()
                };
                setSubmitting(false);
            }}>
            {formikProps => {
                const { handleChange, values, handleSubmit, errors } = formikProps;
                return (
                    <Container>
                        <ContainerContent>
                            <Content>
                                <H2 center bold color={colors.primary} style={{ marginBottom: spacing.medium }}>
                                    User Password Reset
                                </H2>
                                <form onSubmit={handleSubmit}>
                                    <div >
                                        <Input
                                            label="Email"
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            value={values.email}
                                            onChange={handleChange}
                                            error={errors.email}
                                            backgroundColor={colors.white}
                                        />
                                    </div>
                                    <div style={{ marginBottom: spacing.medium }}>
                                        <Input
                                            label="Name"
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={errors.name}
                                            backgroundColor={colors.white}
                                        />
                                    </div>

                                    <Input
                                        label="Password"
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        value={values.password}
                                        onChange={handleChange}
                                        error={errors.password}
                                        backgroundColor={colors.white}
                                    />
                                    <div >
                                        <Input
                                            label="Token"
                                            type="text"
                                            name="token"
                                            placeholder="Token"
                                            value={values.token}
                                            onChange={handleChange}
                                            error={errors.token}
                                            backgroundColor={colors.white}
                                        />
                                    </div>
                                    <div onClick={async () => {
                                        if (!values?.email) {
                                            showMessage({
                                                type: 'error',
                                                message: "Email is required to resend token"
                                            })
                                            return;
                                        }
                                        await dispatch(resendToken(values?.email));

                                    }} className="d-flex justify-content-end align-items-center">Resend Token</div>
                                    <br />
                                    <Button type="submit" text="Submit" disabled={status === 'loading' ? true : false} />
                                </form>
                            </Content>
                        </ContainerContent>
                    </Container>
                );
            }}
        </Formik>
    );
}

export default Login;
