import { useFormik } from "formik";
import { ChevronLeft } from "lucide-react";
import qs from "query-string";
import { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import { toast } from 'react-toastify';
import { Oval } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Cookies from "js-cookie";
import { AppContainer } from "../../atoms";
import CustomToast from "../../atoms/CustomToast/index"
import { ApprovalsTable, FilterCard, FilterLabel, PageTabIndicator, Pagination, PaymentEmptyState, SingleCountCard, ZojaCheckbox, ZojalActionModal } from "../../components";
import { ZojaButton, ZojaModal, ZojaSelect } from "../../components/tailwind";
import ZojaAInfoModal from "../../components/modalTypes/ZojaInfoModal";
import { NotificationIcon, NotificationModal, SingleNotification } from '../../components';
import { cn } from "../../lib/utils";
import { useAppDispatch } from "../../redux";
import {

    Button,

} from '../../components';
import { initializeAudio, playNotificationSound } from "../../utils/notificationSound";
import { useAppSelector } from "../../redux/redux-hooks";
import { getApprovalsMetricRequest, getApprovalsRequest, reviewTransactionRequest, reviewTransactionReset } from "../../redux/slice";
import { Dictionary } from "../../types";
import {
    currencyFormat,
    getSelectedOptionValue,
    handleSelectedOption,
    icons,
    images,
    useDelayedFunction
} from '../../utils';
import { dateTimeFormat3 } from "../../utils/dateFormat";
import { ApprovalTabs } from "./data";
import Pusher from "pusher-js";



const sortByOtions = {
    aphabetically: [
        {
            id: 1,
            value: 'az',
            title: 'A-Z',
            isChecked: false,
        },
        {
            id: 2,
            value: 'za',
            title: 'Z-A',
            isChecked: false,
        },
    ],
    orderBy: [
        {
            id: 1,
            value: 'newest',
            title: 'Newest',
            isChecked: false,
        },
        {
            id: 2,
            value: 'oldest',
            title: 'Oldest',
            isChecked: false,
        },
    ],
};

type LEVEL_TYPE = 'step-1' | 'step-2'
type LIMIT_TYPE = 'level-one' | 'level-two'
type REVIEW_TYPE = 'approved' | 'rejected'

const LEVEL_MAP = {
    'step-1': 'level-one',
    'step-2': 'level-two',
}
const LIMIT_MAP = {
    'level-one': 'Level 1',
    'level-two': 'Level 2',
}



export default function TransactionApprovals() {
    const levelOneApproval = Cookies.get("eligibleLevelOneApproval") === "true";
    const levelTwoApproval = Cookies.get("eligibleLevelTwoApproval") === "true";
    const [searchValue, setSearchValue] = useState('');
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false)
    const [showSortBy, setShowSortBy] = useState(false);
    const [sortBy, setSortBy] = useState(sortByOtions.aphabetically);
    const [orderBy, setOrderBy] = useState(sortByOtions.orderBy);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(10);
    const [modalData, setModalData] = useState({
        approve: false,
        reject: false,
        details: false,
        success: false,
    })
    const [showExportOptions, setShowExportOptions] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Dictionary>({});
    const [reviewType, setReviewType] = useState('');

    const pusher_app_key = "4f07eceda0cc740b59d5";
    const pusher_app_cluster = "mt1";
    const pusher_app_event = "approval.update";
    const pusher_channel_name = 'user_' + Cookies.get('x-id')


    const firstRender = useRef(true)

    const { search, pathname } = useLocation();
    const { view, ui } = qs.parse(search)

    const [payload, setPayload] = useState({
        search: '',
        level: LEVEL_MAP[view as LEVEL_TYPE],
        status: ui,
        sort_by: '',
        order_by: '',
        page: currentPage,
        per_page: totalPages
    });


    useEffect(() => {
        const pusher = new Pusher(pusher_app_key, {
            cluster: pusher_app_cluster,
        });
        pusher.connection.bind("state_change", (states: any) => {
        });

        // Listen for successful connection
        pusher.connection.bind("connected", () => {
        });

        // Listen for disconnection
        pusher.connection.bind("disconnected", () => {
        });

        const channel = pusher.subscribe(pusher_channel_name);
        channel.bind(pusher_app_event, (data: any) => {
            handleGetApprovalsMetrics(view as LEVEL_TYPE)
            handleGetApprovals(payload)
        });

        return () => {
            channel.unbind_all();
            channel.unsubscribe();
            pusher.disconnect();
        };
    }, []);

 

    const getApprovalsMetricState = useAppSelector((state) => state.getApprovalsMetric)
    const approvalMetricStatus = getApprovalsMetricState?.status
    const approvalMetricData = getApprovalsMetricState?.data?.approvals_count ?? {}

    const getApprovalsState = useAppSelector((state) => state.getApprovals)
    const approvalsStatus = getApprovalsState?.status
    const approvalsData = getApprovalsState?.data?.transaction_approvals?.data ?? []

    const reviewTransactionState = useAppSelector((state) => state.reviewTransaction)
    const reviewTransactionStatus = reviewTransactionState?.status


    const getApprovalData = ((data: Dictionary[]) => {
        return data?.map((item: Dictionary, idx: number) => {
            const { amount, sender_name, receiver_account, receiver_bank } = item?.transaction_details
            if (payload.status === "pending") {
                return {
                    id: item?.id,
                    sender_name,
                    amount: currencyFormat(Number(amount), false, 'NGN '),
                    account_number: receiver_account,
                    destination_bank: receiver_bank,
                    limit_type: LIMIT_MAP[item?.level as LIMIT_TYPE],
                    created_at: item?.created_at,
                }
            }
            if (payload.status === "approved") {
                return {
                    id: item?.id,
                    sender_name,
                    amount: currencyFormat(Number(amount), false, 'NGN '),
                    approved_by: item?.approver,
                    created_at: item?.approved_at,
                }
            }
            if (payload.status === "rejected") {
                return {
                    id: item?.id,
                    sender_name,
                    amount: currencyFormat(Number(amount), false, 'NGN '),
                    rejected_by: item?.approver,
                    rejection_reason: item?.comment,
                    created_at: item?.approved_at,
                }
            }
            return item
        })
    })

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { delayedFunction } = useDelayedFunction(1000)

    const ApprovalCards = [
        {
            id: 1,
            helper: 'pending',
            title: 'Pending Transactions',
            total: approvalMetricData?.pending_approval_count || 0,
            subtitle: 'Total Records',
        },
        {
            id: 2,
            helper: 'approved',
            title: 'Approved Transactions',
            total: approvalMetricData?.approved_approval_count || 0,
            subtitle: 'Total Records',
        },
        {
            id: 3,
            helper: 'rejected',
            title: 'Rejected Transactions',
            total: approvalMetricData?.rejected_approval_count || 0,
            subtitle: 'Total Records',
        },
    ];

    const formik = useFormik({
        initialValues: {
            comment: ''
        },
        validationSchema: yup.object().shape({
            comment: yup.string().required()
        }),
        onSubmit: () => {
            handleReviewTransaction('rejected')
        }
    })

    const handleSubmitFilter = () => {
        const getSelectedSortBy = getSelectedOptionValue(sortBy);
        const getSelectedOrderBy = getSelectedOptionValue(orderBy);

        setPayload({
            ...payload,
            sort_by: getSelectedSortBy,
            order_by: getSelectedOrderBy,
        })

    }

    const handleExportFile = (type: string) => { }

    const handleGetApprovalsMetrics = (level: LEVEL_TYPE) => {
        dispatch(getApprovalsMetricRequest({ level: LEVEL_MAP[level] }))
    }

    const handleReviewTransaction = (review_type: REVIEW_TYPE) => {
        let payload
        if (review_type === "approved") {
            payload = {
                approval_id: selectedItem?.id,
                status: review_type,
            }
        } else {
            payload = {
                approval_id: selectedItem?.id,
                status: review_type,
                comment: formik.values.comment
            }
        }
        setReviewType(review_type)
        formik.resetForm(); // This resets the form to its initial state.
        dispatch(reviewTransactionRequest(payload))
    }

    const handleGetApprovals = (data: Dictionary) => {
        dispatch(getApprovalsRequest(data))
        setShowFilterOptions(false);
        setShowSortBy(false);
        setShowExportOptions(false);
    }

    const handleSearchApprovals = (param: string) => {
        setPayload({
            ...payload,
            search: param
        })
    }

    useEffect(() => {
        handleGetApprovalsMetrics(view as LEVEL_TYPE)
        handleGetApprovals(payload)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!firstRender.current) {
            handleGetApprovals(payload)
        }
        return () => {
            firstRender.current = false
        }
    }, [payload])

    useEffect(() => {
        if (approvalsStatus === 'succeeded') {

            const {
                meta: { last_page },
            } = getApprovalsState?.data?.transaction_approvals;

            setTotalPages(last_page);
        }
    }, [approvalsData]);

    useEffect(() => {
        if (reviewTransactionStatus === "succeeded") {
            setModalData({
                ...modalData,
                reject: false,
                approve: false
            })
            handleGetApprovals(payload)
            handleGetApprovalsMetrics(view as LEVEL_TYPE)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewTransactionStatus])


    useEffect(() => {

        const handleFirstInteraction = () => {
            initializeAudio(); // Initialize on user interaction
            window.removeEventListener("click", handleFirstInteraction);
        };

        window.addEventListener("click", handleFirstInteraction);

        return () => {
            window.removeEventListener("click", handleFirstInteraction);
        };
    }, []);

    return (
        <div>
            <AppContainer navTitle={
                <span className="tw-ml-3 tw-text-[#5E6366] tw-text-xs tw-font-medium md:tw-text-[1.2rem]">Approvals</span>
            }
                className="!tw-h-screen !tw-overflow-hidden"
                
            >

                <section className="tw-py-6 tw-h-full tw-overflow-y-aut">

                    {(showFilterOptions || showSortBy || showExportOptions) && (
                        <div
                            className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen tw-top-0 tw-left-0"
                            onClick={() => {
                                setShowFilterOptions(false);
                                setShowSortBy(false);
                                setShowExportOptions(false);
                            }}></div>
                    )}



                    <PageTabIndicator tabData={levelTwoApproval ? ApprovalTabs : [
                        {
                            id: 1,
                            title: 'Stage 1 Approvals',
                            url: 'step-1',
                        }]} selected={view as string} setSelected={(item) => {
                            if (approvalsStatus !== 'loading') {
                                navigate(`${pathname}?view=${item}&ui=pending`)
                                handleGetApprovalsMetrics(item)
                                setPayload({
                                    ...payload,
                                    level: LEVEL_MAP[item as LEVEL_TYPE],
                                    status: 'pending'
                                })
                            }
                        }} />
                    <br />


                    <div className="tw-grid tw-gap-4 tw-grid-cols-2 xl:tw-grid-cols-4 md:tw-gap-6">
                        {ApprovalCards.map(({ id, helper, title, total, subtitle }) => (
                            <SingleCountCard
                                key={id}
                                id={id}
                                title={title}
                                total={total}
                                subtitle={subtitle}
                                active={ui === helper}
                                onClick={(item: any) => {
                                    if (approvalsStatus !== 'loading') {
                                        navigate(`${pathname}?view=${view}&ui=${helper}`)
                                        setPayload({
                                            ...payload,
                                            level: LEVEL_MAP[view as LEVEL_TYPE],
                                            status: helper,
                                        })
                                    }
                                }}
                                loading={approvalMetricStatus === "loading"}

                            />
                        ))}
                    </div>


                    <div className="tw-mb-4 md:tw-h-[70vh] md:tw-mb-28 tw-mt-10 tw-overflow-y-auto">
                        <div className="tw-my-3 tw-flex tw-flex-col tw-justify-between md:tw-flex-row ">
                            <p className="tw-flex tw-items-center tw-gap-x-2 tw-text-lg tw-text-[#222B88] tw-font-[400] tw-order-1 md:-tw-order-1">
                                <span>{ui === "pending" ? 'Outgoing Transaction' : 'Transactions'}</span>
                                {/* <span className="tw-w-6 tw-h-6 tw-rounded-[4px] tw-p-2 tw-bg-isPrimary/5 tw-grid tw-place-content-center">
                                0
                            </span> */}
                            </p>
                            <div className="tw-flex tw-flex-col tw-gap-2 tw-items-end lg:tw-flex-row lg:tw-gap-x-3 md:tw-items-center">
                                <div className="tw-relative tw-w-full md:tw-mr-4">
                                    <input
                                        type="text"
                                        name="reference"
                                        id="reference"
                                        value={searchValue}
                                        onChange={e => {
                                            setSearchValue(e.target.value)
                                            delayedFunction(() => handleSearchApprovals(e.target.value))
                                        }}
                                        className="tw-w-full tw-pl-1 tw-bg-none tw-border-b tw-text-sm tw-text-isPrimary placeholder:tw-text-xs placeholder:tw-text-[#162082] placeholder:tw-font-thin focus:tw-outline-none md:tw-w-72"
                                        placeholder="Search by username or account number"
                                        style={{ backgroundColor: 'transparent' }}
                                    />
                                    <span
                                        className="tw-text-xs tw-absolute tw-right-0 tw-top-1 tw-text-isPrimary tw-cursor-pointer"
                                        onClick={() => handleSubmitFilter()}>
                                        <icons.FaSearch />
                                    </span>
                                </div>
                                <div className="tw-flex tw-items-center tw-gap-x-2 md:tw-gap-x-4">
                                    <FilterLabel
                                        title="Sort By"
                                        icon={<icons.TbArrowsSort color="#5E6366" />}
                                        onClick={() => setShowSortBy(true)}>
                                        <FilterCard
                                            showFilter={showSortBy}
                                            title="Sort by"
                                            onClick={() => handleSubmitFilter()}
                                            submitButtonText="Apply"
                                            extraClass="tw-w-[10rem] -tw-left-28">
                                            <>
                                                <div className="tw-pt-2">
                                                    {sortBy.map((item: any) => (
                                                        <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                                            <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                                                            <ZojaCheckbox
                                                                isChecked={item.isChecked}
                                                                onClick={() => handleSelectedOption(item, sortBy, setSortBy)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="tw-pt-2 tw-border-">
                                                    {orderBy.map((item: any) => (
                                                        <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                                            <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                                                            <ZojaCheckbox
                                                                isChecked={item.isChecked}
                                                                onClick={() => handleSelectedOption(item, orderBy, setOrderBy)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        </FilterCard>
                                    </FilterLabel>

                                    {/* <ExportLabel
                                    title="Export"
                                    onClick={() => setShowExportOptions(true)}
                                    loading={false}>
                                    <FilterCard
                                        showFilter={showExportOptions}
                                        title="Export as"
                                        onClick={() => null}
                                        showSubmitButton={false}
                                        extraClass="tw-w-[10rem] -tw-left-28">
                                        <div className="tw-flex tw-flex-col tw-mt-4">
                                            <span
                                                className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                                                onClick={() => handleExportFile('pdf')}>
                                                PDF
                                            </span>
                                            <span
                                                className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                                                onClick={() => handleExportFile('csv')}>
                                                CSV
                                            </span>
                                        </div>
                                    </FilterCard>
                                </ExportLabel> */}
                                </div>
                            </div>
                        </div>



                        {approvalsStatus === 'loading' ? (
                            <>
                                <div className="tw-h-[60vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                                    <Oval
                                        height="80"
                                        width="80"
                                        color="#222b88cf"
                                        ariaLabel="tail-spin-loading"
                                        secondaryColor="#222b882b"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                            </>
                        ) :
                            approvalsStatus === "succeeded" && approvalsData?.length > 0 ?
                                <>
                                    <ApprovalsTable
                                        data={getApprovalData(approvalsData)}
                                        tab={ui as string}
                                        handleRowClick={(id: any) => {
                                            const data = approvalsData?.filter((f: Dictionary) => f.id === id)?.[0]
                                            setSelectedItem(data)
                                            ui === "pending" && setShowInfoModal(true)
                                            if (ui !== "pending") {
                                                setModalData({ ...modalData, details: true })
                                            }
                                        }}
                                    />

                                </>
                                :
                                <div>
                                    <PaymentEmptyState
                                        text="No record available"
                                    />
                                </div>
                        }

                        {approvalsData.length !== 0 && approvalsStatus === 'succeeded' && (
                            <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
                                <Pagination
                                    isLoading={approvalsStatus === 'succeeded' ? false : true}
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={selectedPage => {
                                        setCurrentPage(selectedPage)
                                        setPayload({
                                            ...payload,
                                            page: selectedPage
                                        })
                                    }}
                                />
                                <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                                    <span className="tw-text-xs tw-w-16">Per page</span>
                                    <ZojaSelect
                                        options={[
                                            { label: '10', value: '10' },
                                            { label: '20', value: '20' },
                                            { label: '30', value: '30' },
                                        ]}
                                        value={approvalsData?.data?.transaction_approvals?.meta?.per_page}
                                        setSelectedOption={selected => {
                                            // handlePageNumberChange(selected)
                                            setPayload({
                                                ...payload,
                                                per_page: selected
                                            })
                                        }}
                                        extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <ZojaAInfoModal
                        show={showInfoModal}
                        handleClose={() => setShowInfoModal(false)}
                        children={
                            <>
                                <h5>Approve Transaction</h5>
                                <span>                                You are about to approve a transaction above the set threshold for transactions, press continue to proceed
                                </span>
                                <div className="tw-flex tw-gap-x-4 tw-my-8" style={{ cursor: 'pointer' }}>
                                    <div className="pt-2 mr-3" onClick={() => {
                                        setShowInfoModal(false)
                                    }}>Cancel</div>
                                    <div>
                                        <ZojaButton extraClass="tw-bg-isPrimary tw-text-white px-3 tw-text-base shadow" text={'Continue'} onClick={() => {
                                            setShowInfoModal(false)
                                            setModalData({ ...modalData, approve: true })
                                        }
                                        } />



                                    </div>

                                </div>

                            </>
                        }
                    />

                    <ZojalActionModal
                        show={modalData.approve}
                        handleClose={() => setModalData({ ...modalData, approve: false })}
                        actionIcon={images.reactivateUser}
                        children={
                            <>
                                <span className="tw-block tw-mt-6 tw-text-sm tw-text-[#162082] tw-w-[70%]">{`Are you sure you want to approve this transaction?`}</span>
                                <div className="tw-flex tw-gap-x-4 tw-my-8">
                                    <ZojaButton
                                        onClick={() => handleReviewTransaction('approved')}
                                        extraClass={cn(
                                            "tw-rounded-lg tw-p-1 tw-px-7 tw-text-sm tw-font-thin tw-text-white tw-bg-green-600", reviewTransactionStatus === 'loading' && "!tw-bg-green-600"
                                        )}
                                        text={'Approve'}
                                        loadingText={'Approve'}
                                        loading={reviewTransactionStatus === 'loading'}
                                        disabled={reviewTransactionStatus === 'loading'}
                                    />
                                    <ZojaButton
                                        onClick={() => setModalData({ ...modalData, reject: true })}
                                        extraClass={`tw-rounded-lg tw-p-1 tw-px-7 tw-text-sm tw-font-thin tw-text-white tw-bg-red-600`}
                                        text="Reject"
                                    />
                                </div>
                                <span className="tw-block tw-mb-6 tw-underline tw-text-sm tw-text-[#162082] tw-w-[70%] tw-cursor-pointer"
                                    onClick={() => {
                                        setModalData({ ...modalData, approve: false })
                                        setModalData({ ...modalData, details: true })
                                    }}
                                >View transaction details</span>
                            </>
                        }
                    />

                    <ZojaModal
                        show={modalData.reject}
                        handleClose={() => {
                            formik.resetForm(); // This resets the form to its initial state.
                            setModalData({ ...modalData, approve: false, reject: false });
                        }}
                        height="auto"
                        position="center"
                        extraClass="tw-mt-12 tw-items-center tw-rounded-xl md:tw-w-[32rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto lg:tw-w-[45rem"
                    >
                        <div className="tw-py-20 tw-rounded-lg tw-p-5 tw-relative">
                            <span
                                className="tw-text-white tw-bg-isPrimary tw-block tw-p-1 tw-rounded-md tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer"
                                onClick={() => {
                                    formik.resetForm(); // Reset form when modal is closed via this button too.
                                    setModalData({ ...modalData, reject: false });
                                }}
                            >
                                <MdClose />
                            </span>
                            <div className="tw-mx-16">
                                <span
                                    className="tw-font-normal tw-text-isPrimary tw-flex tw-items-center tw-gap-1 tw-cursor-pointer"
                                    onClick={() => {
                                        formik.resetForm(); // Reset form when navigating back.
                                        setModalData({ ...modalData, reject: false, approve: true });
                                    }}
                                >
                                    <ChevronLeft /> Back
                                </span>
                                <h6 className="tw-text-gray-700 tw-my-6">Reason for Rejection</h6>
                                <form onSubmit={formik.handleSubmit}>
                                    <textarea
                                        className={cn(
                                            "tw-mb-4 tw-border tw-w-full tw-rounded-md tw-border-gray-300 tw-p-2 focus:tw-border-none focus:tw-outline",
                                            formik.errors.comment && "tw-border-red-500"
                                        )}
                                        name="comment"
                                        onChange={formik.handleChange}
                                        rows={5}
                                        value={formik.values.comment}
                                        placeholder="Enter reason for rejection"
                                    ></textarea>
                                    <ZojaButton
                                        extraClass={cn(
                                            "tw-rounded-md tw-p-3 tw-px-7 tw-text-sm tw-font-thin tw-text-white tw-bg-red-500 tw-w-full tw-flex tw-justify-center tw-items-center",
                                            (!formik.isValid || !formik.dirty) &&
                                            "!tw-bg-red-300 tw-cursor-not-allowed",
                                            reviewTransactionStatus === "loading" && "!tw-bg-red-400"
                                        )}
                                        text="Reject"
                                        disabled={
                                            (!formik.isValid || !formik.dirty) ||
                                            reviewTransactionStatus === "loading"
                                        }
                                        loading={reviewTransactionStatus === "loading"}
                                        loadingText="Reject"
                                    />
                                </form>
                            </div>
                        </div>
                    </ZojaModal>


                    {/* Transaction details */}
                    <ZojaModal
                        show={modalData.details}
                        handleClose={() => setModalData({ ...modalData, details: false })}
                        height='auto'
                        position='center'
                        extraClass='tw-mt-12 tw-items-center tw-rounded-xl md:tw-w-[35rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto lg:tw-w-[48rem]'
                    >
                        <div className='tw-rounded-lg tw-text-center tw-p-5 tw-flex tw-flex-col tw-items-center tw-relative tw-py-12'>
                            <span className='tw-text-white tw-bg-isPrimary tw-block tw-p-1 tw-rounded-md tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer' onClick={() => setModalData({ ...modalData, details: false })}><MdClose /></span>
                            <div className="tw-text-center">
                                <h4 className="">Transaction Details</h4>
                                <div className="tw-mt-5">
                                    <span className="tw-text-gray-500">Amount</span>
                                    <h2 className="tw-text-blue-300">{selectedItem?.transaction_details?.amount ? currencyFormat(Number(selectedItem?.transaction_details?.amount), false, 'NGN ') : '---'}</h2>
                                </div>
                            </div>
                            <div className="tw-mt- tw-w-[90%] tw-mx-auto">
                                <table className="tw-w-full tw-mx-auto tw-border-separate tw-border-spacing-y-4">
                                    <thead>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <SingleLabel title="Transaction type" value={selectedItem?.transaction_type || '---'} />
                                            <SingleLabel title="Account Name" value={selectedItem?.transaction_details?.sender_name ?? '---'} />
                                            <SingleLabel title="Account Number" value={selectedItem?.transaction_details?.sender_account ?? '---'} />
                                        </tr>
                                        <tr>
                                            <SingleLabel title="Destination Bank" value={selectedItem?.transaction_details?.receiver_bank ?? '---'} />
                                            <SingleLabel title="Receiver Name" value={selectedItem?.transaction_details?.receiver_name ?? '---'} />
                                            <SingleLabel title="Receiver acct number" value={selectedItem?.transaction_details?.receiver_account ?? '---'} />
                                        </tr>
                                        <tr>
                                            <SingleLabel title="Phone number" value={selectedItem?.transaction_details?.sender_phone ?? '---'} />
                                            <SingleLabel title="Email" value={selectedItem?.transaction_details?.sender_email ?? '---'} />
                                            <SingleLabel title="Date-Time" value={dateTimeFormat3(selectedItem?.created_at) ?? '---'} />
                                        </tr>
                                    </tbody>
                                </table>
                                {ui !== "pending" && <div className="tw-text-left">
                                    <span className={cn(
                                        "tw-text-green-500 tw-text-sm tw-font-medium",
                                        ui === "rejected" && "tw-text-red-500"
                                    )}>{ui === "rejected" ? "Rejected by" : "Approved by"}</span>
                                    <span className="tw-block tw-text-gray-800 tw-font-normal">{selectedItem?.approver || '---'}</span>
                                </div>}
                                {ui === "rejected" && <div className="tw-mt-3 tw-text-left">
                                    <span className="tw-text-gray-500 tw-text-sm">Rejection reason</span>
                                    <span className="tw-block tw-text-gray-700">{selectedItem?.comment || '---'}</span>
                                </div>
                                }
                            </div>

                        </div>
                    </ZojaModal>

                    <ZojaModal
                        show={reviewTransactionStatus === "succeeded"}
                        handleClose={() => {
                            dispatch(reviewTransactionReset())
                        }}
                        height='auto'
                        position='center'
                        extraClass="md:tw-w-[30rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                        borderRadius=".5rem"
                    >
                        <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                            {/* <h3 className='tw-text-[.9rem] tw-text-[#263238]'>Success!</h3> */}
                            <img src={images.successIconGif} alt="" />
                            <h3 className='tw-text-[1.4rem] tw-text-center tw-text-[#263238]'>{`Transaction ${reviewType === 'approved' ? 'Approved' : 'Rejected'}`}</h3>
                            <span className="tw-block tw-text-center tw-text-[.9rem]">This transaction by <span className="tw-font-medium">{selectedItem?.transaction_details?.sender_account}</span> has been successfully {`${reviewType === 'approved' ? 'approved' : 'rejected'}`}</span>

                            <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                                onClick={() => {
                                    dispatch(reviewTransactionReset())
                                }}
                            ><icons.MdClose /></span>
                            <div className='my-3 mt-4'>
                                <ZojaButton
                                    onClick={() => {
                                        dispatch(reviewTransactionReset())
                                    }}
                                    text="Close"
                                    extraClass='tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2 tw-mt-4 hover:tw-bg-isPrimary/90 disabled:tw-bg-blue-900'
                                />
                            </div>
                        </div>
                    </ZojaModal>

                </section>
            </AppContainer>
        </div>
    )
}

const SingleLabel = ({ title, value }: { title: string, value: string }) => {
    return (
        <td className="tw-text-left">
            <span className="tw-text-gray-500 tw-text-sm">{title}</span>
            <span className="tw-block tw-mt-1 tw-text-gray-800 tw-font-normal">{value}</span>
        </td>
    )
}
