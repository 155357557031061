import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Dictionary } from "../../types";
import api from "../../api/api";


interface InitState {
    data: Dictionary;
    status: "idle" | "loading" | "succeeded" | "failed";
    error: any;
}
const initialState: InitState = {
  data: {},
  status: 'idle',
    error: null
}

const dashboardNotificationSlice = createSlice({
    name: 'dashboardNotification',
    initialState,
    reducers: {
        reset: (state) => {
            Object.assign(state, initialState);
        },
    },
    extraReducers: (builder) => {
     

        builder.addCase(getNotificationsList.pending, (state) => {
            state.status = "loading";
        });
        builder.addCase(getNotificationsList.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(getNotificationsList.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        });
    },

})



  export const getNotificationsList = createAsyncThunk(
    'getSystemNotifications',
    async (payload: Dictionary, { dispatch }) => {
      const url = `admin/notifications`;
  
      try {
        const response = await api.get(`${url}`);
        return response?.data;
      } catch (err) {
        throw err;
      }
    },
  );

export const dashboardNotificationReset = dashboardNotificationSlice.actions.reset
export const dashboardNotificationReducer = dashboardNotificationSlice.reducer