import React, { useEffect, useState } from 'react';
import { Dictionary } from '../../../types';
import { ZojaTable } from '../..';
import { TD, TR } from '../../tailwind/table/tableElements';
import { dateTimeFormat3 } from '../../../utils/dateFormat';
import { FaChevronRight } from 'react-icons/fa';
import { useAppDispatch } from '../../../redux/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { routesPath } from '../../../utils';
import { cn } from '../../../lib/utils';
const { KYCCUSTOMERINFORMATION } = routesPath;

interface IProps {
  data: Dictionary[];
  level?: string;
  tab?: string;
  sorting?: any;
  order?: string;
  handleRowClick?: (arg?: any) => void;
}

const pendingTableColumns = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Sender Name',
    value: 'sender_name',
    hasSortIcon: false,
  },
  {
    id: 3,
    title: 'Transaction Amount',
    value: 'amount',
    hasSortIcon: false,
  },
  {
    id: 4,
    title: 'Account Number',
    value: 'account_number',
    hasSortIcon: false,
  },
  {
    id: 5,
    title: 'Destination Bank',
    value: 'destination_bank',
    hasSortIcon: false,
  },
  {
    id: 6,
    title: 'Limit Type',
    value: 'limit_type',
    hasSortIcon: false,
  },
  {
    id: 7,
    title: 'Date Created',
    value: 'created_at',
    hasSortIcon: false,
  },
  {
    id: 8,
    title: '',
    hasSortIcon: false,
  },
];
const approvedTableColumns = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Sender Name',
    value: 'sender_name',
    hasSortIcon: false,
  },
  {
    id: 3,
    title: 'Transaction Amount',
    value: 'amount',
    hasSortIcon: false,
  },
  {
    id: 4,
    title: 'Approved by',
    value: 'approved_by',
    hasSortIcon: false,
  },
  {
    id: 5,
    title: 'Date-Time',
    value: 'created_at',
    hasSortIcon: false,
  },
  {
    id: 7,
    title: '',
    hasSortIcon: false,
  },
];

const rejectedTableColumns = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Sender Name',
    value: 'sender_name',
    hasSortIcon: false,
  },
  {
    id: 3,
    title: 'Transaction Amount',
    value: 'amount',
    hasSortIcon: false,
  },
  {
    id: 4,
    title: 'Rejected by',
    value: 'rejected_by',
    hasSortIcon: false,
  },
  {
    id: 5,
    title: 'Rejection reason',
    value: 'rejected_reason',
    hasSortIcon: false,
  },
  {
    id: 6,
    title: 'Date-Time',
    value: 'created_at',
    hasSortIcon: false,
  },
  {
    id: 7,
    title: '',
    hasSortIcon: false,
  },
];

const ApprovalsTable = ({ data, tab, level, sorting, order, handleRowClick }: IProps) => {
  const [approvalTableColumns, setApprovalTableColumns] = useState(pendingTableColumns);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleRowClicked = (id: any) => {
    handleRowClick && handleRowClick(id);
    // navigate(`${KYCCUSTOMERINFORMATION}${id}`, {
    //   state: {
    //     kycLvl: level,
    //     verificationType:
    //       tab === 'verified_users'
    //         ? verifiedUsers
    //         : tab === 'pending_verifications'
    //         ? pendingUsers
    //         : tab === 'rejected_verifications'
    //         ? rejectedUsers
    //         : '',
    //   },
    // });
  };

  useEffect(() => {
    if (tab === 'rejected') {
      setApprovalTableColumns(rejectedTableColumns);
    } else if (tab === 'approved') {
      setApprovalTableColumns(approvedTableColumns);
    } else {
      setApprovalTableColumns(pendingTableColumns);
    }
  }, [tab]);

  return (
    <div>
      <ZojaTable column={approvalTableColumns} sorting={sorting} order={order}>
        {data?.map((item: Dictionary, idx: number) => (
          <TR key={item.id}>
            <TD value={idx + 1} />
            <TD>{item.sender_name}</TD>
            <TD>{item.amount}</TD>
            <TD extraClass={cn(tab !== "approved" && "tw-hidden")}>{item.approved_by}</TD>
            <TD extraClass={cn(tab !== "rejected" && "tw-hidden")}>{item.rejected_by}</TD>
            <TD extraClass={cn(tab !== "rejected" && "tw-hidden")}>{item.rejection_reason}</TD>
            <TD extraClass={cn((tab === "approved" || tab === "rejected") && "tw-hidden")}>{item.account_number}</TD>
            <TD extraClass={cn((tab === "approved" || tab === "rejected") &&  "tw-hidden")}>{item.destination_bank}</TD>
            <TD extraClass={cn((tab === "approved" || tab === "rejected") && "tw-hidden")}>{item.limit_type}</TD>
            <TD value={dateTimeFormat3(item.created_at)} extraClass="tw-font-thin" />
            <TD
              // extraClass={`${tab !== 'rejected_verifications' ? '' : 'tw-hidden'}`}
              onClick={() => {handleRowClicked(item.id)}}>
              <span className="tw-text-xs">
                <FaChevronRight />
              </span>
            </TD>
          </TR>
        ))}
      </ZojaTable>
    </div>
  );
};

export default ApprovalsTable;