import React from 'react'
import { convertToReadableTime } from '../../utils/helpers/index';
interface IProps {
    title: string,
    content: string,
    onClick?: () => void,
    isRead?: boolean,
    created_at:any,
}

const SingleNotification = ({ title, content, created_at, onClick, isRead = false }: IProps) => {
    const getCurrentDate = () => {
        const today = new Date();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(today.getDate()).padStart(2, '0');
        const year = today.getFullYear();

        return `${month}-${day}-${year}`;
    };

    return (
        <div className={`${isRead ? 'tw-bg-[#F4F4F4] tw-border tw-border-[#E5E9EB]' : 'tw-bg-white tw-shadow-zojaShadowEight'} tw-p-3 tw-py-4 tw-rounded-lg tw-mt-4 tw-cursor-pointer`}
            onClick={onClick}
        >
            <div className="tw-flex tw-justify-between tw-items center">
                <h4 className="tw-text-[#323348] tw-text-sm">{title}</h4>
                {!isRead ? <span className="tw-w-1.5 tw-h-1.5 tw-bg-red-600 tw-rounded-full tw-block"></span> : null}
            </div>
            <div className="tw-text-[#5E6366] tw-text-[11px] tw-w-[90%]">
                {content}
            </div>
            <div className="tw-text-[#5E6366] tw-text-[11px] tw-w-[90%]">
                {getCurrentDate()},  {convertToReadableTime(created_at)}</div>

        </div>
    )
}

export default SingleNotification