import * as yup from 'yup';
import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FiFilter } from 'react-icons/fi';
import { H2 } from '../../styles';
import {
  DatePicker,
  BorderedText,
  Pagination,
  SearchInput,
  ReconcialiationCard,
  Modal,
  Picker,
  Input,
  TextArea,
  Button,
  FilterCard,
  ZojaCheckbox,
  FilterColapsible,
  FilterLabel,
} from '../../components';
import { TabView, MoreIconView, AppContainer, SuccessModalWithCopy, TransactionDetailsModal } from '../../atoms';
import {
  colors,
  dateFormat,
  spacing,
  yearDateFormat,
  routesPath,
  icons,
  timeFormat,
  showMessage,
  capitalizeFirstLetter,
  useSortTable,
  formatStartAndEndDateWithDelimeter,
  handleSelectedOptionHandler,
  replaceStringWithBackslach,
  useDelayedFunction,
} from '../../utils';
import {
  TabViewContainer,
  TabContentTwo,
  ReconciliationSearchContainer,
  EscalateFormContainer,
  EscalateBtnContainer,
  CustomerNameContainer,
} from './style';

import {
  getTransactionsRequest,
  getEscalationAgentsRequest,
  getTransactionByIdRequest,
  createEscalationTicketRequest,
  createEscalationTicketReset,
  getReconciliationAccountRequest,
  exportTransactionByIdToMailReset,
  downloadTransactionByIdRequest,
} from '../../redux/slice';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { Oval } from 'react-loader-spinner';
import { ZojaButton, ZojaSelect, ZojaInput } from '../../components/tailwind';
import ReconciliationTable from '../../components/tables/reconciliation/ReconciliationTable';
import { dateRangeSelect } from '../../utils/dateRangeOptions';

type Dictionary = {
  [key: string]: any;
};
const tabViewData = [
  { id: 1, isSelected: true, text: 'Transactions History' },
  // { id: 2, isSelected: false, text: "Bills History" },
  // { id: 3, isSelected: false, text: "Cash Request History" },
];

const transactionDataHeader = [
  {
    id: 1,
    title: '',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Customer',
    value: 'name',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'Transaction ID',
    value: 'tid',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Amount',
    value: 'amount',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Transaction Type',
    value: 'type',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Status',
    value: 'status',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Date',
    value: 'time',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: '',
    hasSortIcon: false,
  },
];

const dropdownList: Dictionary = [
  {
    id: 1,
    title: 'Transaction Details',
    value: 'transactionDetails',
  },
  {
    id: 2,
    title: 'Escalate',
    value: 'escalate',
  },
];

const { RECONCILIATIONUSERDETAILS } = routesPath;

function Reconciliation() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedFailedTransaction, setSelectedFailedTransaction] = useState<any>({});
  const [tabViewSelectedIndex, setTabViewSelectedIndex] = useState<any[number]>(1);
  const [startDisplayRecordDate, setStartDisplayRecordDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [endDisplayRecordDate, setEndDisplayRecordDate] = useState('');
  const [transactionDataList, setTransactionDataList] = useState<any[]>([]);
  const pageSize = 10;
  const [transactionFilterParams, setTransactionFilterParams] = useState({
    type: '',
    status: '',
    start_date: '',
    end_date: '',
    page: currentPage,
    search: '',
  });
  const [payload, setPayload] = useState<Dictionary>({
    type: '',
    date_range: '',
    search: '',
    exp: '',
  });

  const [tStatus] = useState(['Transaction Status', 'Transaction Status']);
  const [tTypes] = useState(['Transaction Type', 'Transaction Type']);
  const [perPage, setPerPage] = useState<any>('10');
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [checkedBox, setCheckedBox] = useState<any>({});
  const [showDateRange, setShowDateRange] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [showAmountRange, setShowAmountRange] = useState(true);
  const [transactionDateRange, setTransactionDateRange] = useState({ start_date: '', end_date: '' });
  const [order, setOrder] = useState('ASC');
  const [userData, setUserData] = useState<Dictionary>({});
  const [totalPages, setTotalPages] = useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [searchProfileValue, setSearchProfileValue] = useState('');
  const [selectedTransactionActionText, setSelectedTransactionActionText] = useState('');
  const [escalationAgentsList, setEscalationAgentsList] = useState<any[]>([]);
  const [moreIsVisible, setMoreIsVisible] = useState(false);
  const [escalateModalVisible, setEscalateModalVisible] = useState(false);
  const [transactionDetailsModalVisible, setTransactionDetailsModalVisible] = useState(false);
  const [selectedEscalateTo, setSelectedEscalateTo] = useState('');
  const [selectedPriorityLevel, setSelectedPriorityLevel] = useState('');
  const [dateRangeOption, setDateRangeOption] = useState(dateRangeSelect);
  const [transactionByIdData, setTransactionByIdData] = useState<Dictionary>({});
  const [escalateSuccessfulModalVisible, setEscalateSuccessfulModalVisible] = useState(false);
  const [escalateSuccessfulData, setEscalateSuccessfulData] = useState<Dictionary>({});
  const transactionDetails = 'Transaction Details';
  const escalate = 'Escalate';
  const moreIconOption = [transactionDetails, escalate];
  const initialValues: Dictionary = {
    model_type: tTypes[0] !== 'Transaction Type' ? replaceStringWithBackslach(tTypes[0]) : '',
    status: tStatus[0] !== 'Transaction Status' ? tStatus[0] : '',
    per_page: pageSize,
    page: currentPage,
    transaction_date: '',
    type: '',
    min_amount: '',
    max_amount: '',
    sort_by: '',
    order_by: '',
    search: '',
  };
  const [filterOptions, setFilterOptions] = useState(initialValues);

  // redux state
  const transactionState = useAppSelector(state => state.getTransactions);
  const { status: getTransactionsStatus } = transactionState;

  const getEscalationAgentsState = useAppSelector(state => state.getEscalationAgents);
  const { status: getEscalationAgentsStatus } = getEscalationAgentsState;

  const getReconciliationAccountState = useAppSelector(state => state.getReconciliationAccount);
  const { status: getReconciliationAccountStatus } = getReconciliationAccountState;

  const createEscalationTicketState = useAppSelector(state => state.createEscalationTicket);
  const { status: createEscalationTicketStatus } = createEscalationTicketState;

  const exportTransactionByIdToMailState = useAppSelector(state => state.exportTransactionByIdToMail);
  const { status: exportTransactionByIdToMailStatus } = exportTransactionByIdToMailState;

  const getTransactionByIdState = useAppSelector(state => state.getTransactionById);

  const { status: getTransactionByIdStatus } = getTransactionByIdState;

  const downloadTransactionByIdState = useAppSelector(state => state.downloadTransactionById);
  const { status: downloadTransactionByIdStatus } = downloadTransactionByIdState;

  const escalateCchema = yup.object().shape({
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
    escalateTo: selectedEscalateTo.length < 2 ? yup.string().required('To who is required') : yup.string(),
    priorityLevel:
      selectedPriorityLevel.length < 2 ? yup.string().required('Priority level is required') : yup.string(),
  });

  useEffect(() => {
    if (getTransactionByIdStatus === 'succeeded') {
      const {
        amount,
        status,
        currency,
        transfer_purpose,
        charge,
        channel,
        created_at,
        external_account_name,
        source,
        user: { name, email, telephone },
      } = getTransactionByIdState.data.transaction;

      const result = {
        amount,
        status,
        currency,
        data: [
          {
            id: 1,
            text: capitalizeFirstLetter(transfer_purpose),
            helper: 'Transaction Type',
          },
          {
            id: 2,
            text: name,
            helper: 'Wallet Name',
          },
          {
            id: 3,
            text: email,
            helper: 'Email',
          },
          {
            id: 4,
            text: telephone,
            helper: 'Phone Number',
          },
          {
            id: 5,
            text: `N${charge}`,
            helper: 'Charges',
          },
          {
            id: 6,
            text: source !== null ? source?.name : external_account_name,
            helper: 'Sender Name',
          },
          {
            id: 7,
            text: channel,
            helper: 'Channel',
          },
          {
            id: 8,
            text: timeFormat(created_at, true),
            helper: 'Time',
          },
          {
            id: 9,
            text: dateFormat(created_at),
            helper: 'Date',
          },
        ],
      };

      setTransactionByIdData(result);
    }
  }, [getTransactionByIdState]);

  

  useEffect(() => {
    if (createEscalationTicketStatus === 'succeeded') {
      setEscalateSuccessfulData(createEscalationTicketState.data.ticket);
      setEscalateSuccessfulModalVisible(true);
      setEscalateSuccessfulData({});
    }
  }, [createEscalationTicketState]);

  useEffect(() => {
    if (exportTransactionByIdToMailStatus === 'succeeded') {
      setTransactionDetailsModalVisible(false);
      showMessage({
        type: 'success',
        message: exportTransactionByIdToMailState?.data?.message,
      });
      dispatch(exportTransactionByIdToMailReset());
    }
  }, [exportTransactionByIdToMailState]);

  // api getTransactions
  useEffect(() => {
    dispatch(getTransactionsRequest({ ...transactionFilterParams, per_page: filterOptions.per_page }));
  }, [transactionFilterParams]);

  useEffect(() => {
    if (getTransactionsStatus === 'succeeded') {
      let updatedList: any[] = [];

      transactionState?.data?.transactions?.data.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          name: item.user.name,
          tid: item.transaction_reference,
          amount: parseFloat(item.amount),
          type: item.type,
          status: item.status,
          icon: true,
          time: item.created_at,
          currency: item.currency,
          phoneNumber: item.user.telephone,
          transId: item.id,
          email: item.user.email,
        });
      });

      const {
        meta: { last_page, per_page },
      } = transactionState?.data?.transactions;

      setTotalPages(last_page);
      setFilterOptions({ ...filterOptions, per_page });
      setTransactionDataList(updatedList);
    }
  }, [transactionState]);

  useEffect(() => {
    if (getReconciliationAccountStatus === 'succeeded') {
      setUserData(getReconciliationAccountState?.data?.user);
    }
  }, [getReconciliationAccountState]);

  useEffect(() => {
    // fetch escalation agents on when escalation is clicked from options
    if (selectedTransactionActionText === escalate) {
      dispatch(getEscalationAgentsRequest({ id: 'user' }));
    }
  }, [selectedTransactionActionText]);

  useEffect(() => {
    if (getEscalationAgentsStatus === 'succeeded') {
      let result: any[] = [];
      getEscalationAgentsState.data.internal_users.forEach((item: any) => {
        result.push({
          value: item.id,
          label: item.name,
        });
      });
      setEscalationAgentsList(result);
    }
  }, [getEscalationAgentsState]);

  const handleTransactionFilter = () => {
    setTransactionFilterParams({
      ...transactionFilterParams,
      type: '',
      status: '',
      start_date: startDisplayRecordDate.length < 2 ? '' : yearDateFormat(startDisplayRecordDate),
      end_date: endDisplayRecordDate.length < 2 ? '' : yearDateFormat(endDisplayRecordDate),
      search: searchValue,
    });
  };

  // handle search
  const handleOnchangeInternalUser = (value: any) => {
    setSearchValue(value);
  };

  // Input changer handler
  const handleInputChange = ({ target: { name, value } }: Dictionary) => {
    setFilterOptions({ ...filterOptions, [name]: value });
  };

  // filter table handler
  const handleSubmitFilter = () => {
    const { transaction_date: transactionDateValue, ...restPayload } = filterOptions;
    const transaction_date_range = formatStartAndEndDateWithDelimeter(
      transactionDateRange.start_date,
      transactionDateRange.end_date,
    );

    const payload =
      transactionDateValue === 'custom'
        ? { ...restPayload, transaction_date: transaction_date_range }
        : { ...restPayload, transaction_date: transactionDateValue };

    dispatch(getTransactionsRequest(payload));
  };

  const handleSearchUserReconciliation = () => {
    dispatch(getReconciliationAccountRequest({ search: searchProfileValue }));
  };

  // handle different excalation modules
  const handleMoreIconOptions = async (item: any) => {
    if (selectedFailedTransaction.hasOwnProperty('name') && item?.title === escalate) {
      setMoreIsVisible(false);
      setEscalateModalVisible(true);
    }
    if (selectedFailedTransaction.hasOwnProperty('name') && item?.title === transactionDetails) {
      setMoreIsVisible(false);
      setTransactionDetailsModalVisible(true);
      dispatch(
        getTransactionByIdRequest({
          transId: selectedFailedTransaction.transId,
        }),
      );
    }
  };

  const handleCloseEscalateModal = () => {
    setEscalateModalVisible(false);
    setSelectedTransactionActionText('');
    setSelectedFailedTransaction({});
  };

  const handleCloseEscalateSuccessfulModal = () => {
    setEscalateSuccessfulModalVisible(false);
    setEscalateSuccessfulData({});
    dispatch(createEscalationTicketReset());
    handleCloseEscalateModal();
  };

  // Custom Hooks
  const { handleSortItems } = useSortTable({
    order: order,
    setOrder,
    data: transactionDataList,
    setData: setTransactionDataList,
  });

  // function to handle filteroptions
  const HandleFilterCard = () => {
    setPayload({
      ...payload,
      date_range: checkedBox?.[0]?.value,
    });
    dispatch(
      getTransactionsRequest({
        ...payload,
        date_range: checkedBox?.[0]?.value,
      }),
    );

    setShowFilterOptions(false);
  };

  // handle page number change
  const handlePageNumberChange = (pageNumber: any) => {
    setFilterOptions({ ...filterOptions, per_page: pageNumber });
    setPerPage(pageNumber);

    dispatch(
      getTransactionsRequest({
        ...filterOptions,
        per_page: pageNumber,
      }),
    );
  };

  const { delayedFunction } = useDelayedFunction(2000);

  useEffect(() => {
    delayedFunction(handleTransactionFilter);
  }, [searchValue]);

  return (
    <AppContainer navTitle="RECONCILIATION">
      <div style={{ marginTop: spacing.small }}>
        <H2 semiBold color={colors.primary} left>
          Find Profile
        </H2>
        {/* Overlay to hide filter options */}
        {showFilterOptions && (
          <div
            className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
            onClick={() => {
              setShowFilterOptions(false);
            }}></div>
        )}
        <ReconciliationSearchContainer>
          <div
            style={{
              width: '70%',
              marginRight: spacing.small,
            }}>
            <SearchInput
              backgroundColor={'transparent'}
              name="searchProfileValue"
              value={searchProfileValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchProfileValue(e.target.value)}
              placeholder="Search by Phone Number or Account Number"
            />
          </div>

          <ZojaButton
            text="Search Record"
            onClick={handleSearchUserReconciliation}
            extraClass={`tw-bg-isPrimary tw-grid tw-place-content-center tw-p-2 tw-font-thin tw-px-6 tw-text-white tw-text-sm ${
              getReconciliationAccountStatus === 'loading' && 'tw-w-[9rem]'
            }`}
            loading={getReconciliationAccountStatus === 'loading'}
          />
        </ReconciliationSearchContainer>

        <div style={{ marginBottom: spacing.large }}>
          {userData.hasOwnProperty('name') && (
            <ReconcialiationCard
              onClick={() => {
                navigate(`${RECONCILIATIONUSERDETAILS}${userData.id}`);
              }}
              name={userData.name}
              kycLevel={userData.kyc_level}
              lastSeen={dateFormat(userData.updated_at)}
            />
          )}
        </div>
        <TabViewContainer>
          <TabView data={tabViewData} setSelectedIndex={setTabViewSelectedIndex} />

          {tabViewSelectedIndex === 1 && (
            <TabContentTwo>
              <SearchInput
                backgroundColor={'transparent'}
                name="SearchValue"
                value={searchValue}
                onChange={(e: any) => handleOnchangeInternalUser(e.target.value)}
                placeholder="By Name, Transaction ID"
              />

              <DatePicker selectedDate={setStartDisplayRecordDate} />
              <DatePicker selectedDate={setEndDisplayRecordDate} />

              <BorderedText
                onClick={handleTransactionFilter}
                backgroundColor={colors.primary}
                color={colors.white}
                icon={<FiFilter color={colors.white} size={15} />}
                text="Filter"
              />

              <FilterLabel title="Filter Options" icon={<icons.BsFilter />} onClick={() => setShowFilterOptions(true)}>
                <FilterCard
                  showFilter={showFilterOptions}
                  title="Filter Options"
                  onClick={() => handleSubmitFilter()}
                  extraClass="tw-w-[13rem]">
                  <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden tw-z-[1]">
                    <ZojaSelect
                      options={[
                        { value: '', label: 'Transaction Type' },
                        { value: 'credit', label: 'Credit' },
                        { value: 'debit', label: 'Debit' },
                      ]}
                      name="transactionType"
                      showArrown={true}
                      setSelectedOption={selected => setFilterOptions({ ...filterOptions, type: selected })}
                      extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88] tw-mb-2"
                      arrowDownClass="tw-right-lg tw-top-3.5"
                    />
                    <ZojaSelect
                      options={[
                        { value: '', label: 'Transaction Status' },
                        { value: 'success', label: 'Success' },
                        { value: 'pending', label: 'Pending' },
                        { value: 'failed', label: 'Failed' },
                      ]}
                      name="transactionStatus"
                      showArrown={true}
                      setSelectedOption={selected => setFilterOptions({ ...filterOptions, status: selected })}
                      extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88]"
                      arrowDownClass="tw-right-lg tw-top-3.5"
                    />
                    <FilterColapsible
                      title="Amount"
                      show={showAmountRange}
                      onClick={() => setShowAmountRange(!showAmountRange)}>
                      <>
                        <ZojaInput
                          label="Min Amount"
                          id="min_amount"
                          type="number"
                          extraClass="tw-text-xs tw-p-2 tw-mb-2"
                          name="min_amount"
                          value={filterOptions.min_amount}
                          onChange={handleInputChange}
                        />
                        <ZojaInput
                          label="Max Amount"
                          id="max_amount"
                          name="max_amount"
                          type="number"
                          extraClass="tw-text-xs tw-p-2 tw-mb-2"
                          value={filterOptions.max_amount}
                          onChange={handleInputChange}
                        />
                      </>
                    </FilterColapsible>
                    {/* <FilterColapsible
                          title="Transaction Date"
                          show={showTransactionDate}
                          onClick={() => setShowTransactionDate(!showTransactionDate)}
                          extraClass="tw-shadow-none">
                          <>
                            <div className="">
                              {transactionDate.map((item: any) => (
                                <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                  <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                  <ZojaCheckbox
                                    isChecked={item.isChecked}
                                    onClick={() => handleSelectedOption(item, transactionDate, setTransactionDate)}
                                  />
                                </div>
                              ))}
                            </div>

                            <div
                              className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${
                                filterOptions.transaction_date === 'custom' && 'tw-max-h-fit'
                              }`}>
                              <div className="tw-mb-2">
                                <CustomDatePicker
                                  label="State Date"
                                  value={new Date()}
                                  setValue={value =>
                                    setTransactionDateRange({ ...transactionDateRange, start_date: value })
                                  }
                                />
                              </div>
                              <div className="tw-mb-2">
                                <CustomDatePicker
                                  label="End Date"
                                  value={new Date()}
                                  setValue={value =>
                                    setTransactionDateRange({ ...transactionDateRange, end_date: value })
                                  }
                                />
                              </div>
                            </div>
                          </>
                        </FilterColapsible> */}
                  </div>
                </FilterCard>
              </FilterLabel>
            </TabContentTwo>
          )}
        </TabViewContainer>

        {getTransactionsStatus === 'succeeded' && tabViewSelectedIndex === 1 && (
          <>
            <div>
              <ReconciliationTable
                data={transactionDataList}
                tableColumns={transactionDataHeader}
                setSelectedTableItem={setSelectedFailedTransaction}
                tab={'verified_users'}
                setOpenMore={setOpenMore}
                dropdownList={dropdownList}
                sorting={handleSortItems}
                order={order}
                handleMoreIconOptions={handleMoreIconOptions}
                setSelectedItem={setSelectedItem}
              />
            </div>
          </>
        )}

        <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
          <Pagination
            isLoading={getTransactionsStatus === 'loading' ? true : false}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={selectedPage => {
              setCurrentPage(selectedPage);
              dispatch(getTransactionsRequest({ ...transactionFilterParams, page: selectedPage }));

            }}
          />
          {getTransactionsStatus !== 'loading' ? (
            <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
              <span className="tw-text-xs tw-w-16">Per page</span>
              <ZojaSelect
                options={[
                  { label: '10', value: '10' },
                  { label: '20', value: '20' },
                  { label: '50', value: '50' },
                  { label: '100', value: '100' },
                  { label: '200', value: '200' },
                ]}
                value={perPage}
                // value={customerAccountStatement?.data?.transactions?.per_page}
                setSelectedOption={selected => handlePageNumberChange(selected)}
                extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        <Modal isModalVisible={escalateModalVisible} closeModal={handleCloseEscalateModal}>
          <Formik
            initialValues={{
              title: '',
              description: '',
              escalateTo: '',
              priorityLevel: '',
            }}
            enableReinitialize={true}
            validationSchema={escalateCchema}
            onSubmit={async (values, { setSubmitting }) => {
              const { title, description } = values;

              await dispatch(
                createEscalationTicketRequest({
                  title,
                  description,
                  internal_user_id: selectedEscalateTo,
                  priority_level: selectedPriorityLevel,
                  customer_telephone: selectedFailedTransaction?.phoneNumber,
                }),
              );

              setSubmitting(false);
            }}>
            {formikProps => {
              const { handleChange, values, handleSubmit, errors } = formikProps;
              return (
                <form onSubmit={handleSubmit}>
                  <EscalateFormContainer>
                    <CustomerNameContainer>
                      <Input
                        label="Customer Name"
                        backgroundColor={colors.white}
                        borderColor={colors.grey}
                        placeholder="Enter title"
                        type="text"
                        value={selectedFailedTransaction?.name}
                        name={'name'}
                        onChange={() => {}}
                      />
                    </CustomerNameContainer>

                    <Input
                      label="Title"
                      backgroundColor={colors.white}
                      borderColor={colors.grey}
                      placeholder="Enter title"
                      type="text"
                      value={values.title}
                      name={'title'}
                      onChange={handleChange}
                      error={errors.title}
                    />

                    <TextArea
                      label="Title"
                      backgroundColor={colors.white}
                      borderColor={colors.grey}
                      placeholder="Type here..."
                      value={values.description}
                      name={'description'}
                      onChange={handleChange}
                      error={errors.description}
                    />

                    <Picker
                      error={errors.escalateTo}
                      label="Escalate to"
                      selectedValue={setSelectedEscalateTo}
                      placeholder="Select Agent"
                      options={escalationAgentsList}
                    />

                    <Picker
                      error={errors.priorityLevel}
                      label="Priority Level"
                      selectedValue={setSelectedPriorityLevel}
                      placeholder="Select Priority"
                      options={[
                        { label: 'Low', value: 'low' },
                        { label: 'Medium', value: 'medium' },
                        { label: 'High', value: 'high' },
                      ]}
                    />
                    <EscalateBtnContainer>
                      <Button
                        type="submit"
                        text="Escalate"
                        disabled={createEscalationTicketStatus === 'loading' ? true : false}
                      />
                      <Button
                        onClick={handleCloseEscalateModal}
                        text="Cancel"
                        disabled={false}
                        secondary
                        borderColor="transparent"
                        color={colors.primary}
                      />
                    </EscalateBtnContainer>
                  </EscalateFormContainer>
                </form>
              );
            }}
          </Formik>
        </Modal>

        {/* Escalation successful modal */}
        <SuccessModalWithCopy
          isModalVisible={escalateSuccessfulModalVisible}
          closeModal={handleCloseEscalateSuccessfulModal}
          text={'Complaint has been escalated with Ticket Id:'}
          copyIconText={'Copy Ticket:Id'}
          title={escalateSuccessfulData?.ticket_reference}
          iconType="sent"
        />

        <TransactionDetailsModal
          status={transactionByIdData?.status}
          amount={transactionByIdData?.amount}
          currency={transactionByIdData?.currency}
          isModalVisible={transactionDetailsModalVisible}
          closeModal={() => setTransactionDetailsModalVisible(false)}
          onClickExportBtn={() =>
            dispatch(
              downloadTransactionByIdRequest({
                transId: selectedFailedTransaction.transId,
              }),
            )
          }
          exportBtnDisabled={downloadTransactionByIdStatus === 'loading' ? true : false}
          data={transactionByIdData?.data}
          isLoading={getTransactionByIdState.status === 'loading' ? true : false}
        />

        <MoreIconView
          setSelectedText={setSelectedTransactionActionText}
          isModalVisible={moreIsVisible}
          closeModal={() => setMoreIsVisible(false)}
          options={moreIconOption}
          onClick={item => handleMoreIconOptions(item)}
        />

        {getTransactionsStatus === 'loading' && (
          <>
            <div className="tw-h-[70vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
              <Oval
                height="80"
                width="80"
                color="#222b88cf"
                ariaLabel="tail-spin-loading"
                secondaryColor="#222b882b"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </>
        )}
      </div>
    </AppContainer>
  );
}

export default Reconciliation;
