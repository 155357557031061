
let audio: HTMLAudioElement | null = null;

// Initialize audio
export const initializeAudio = (): void => {
  if (!audio) {
    audio = new Audio("/sounds/message-alert.mp3"); // Reference file in public folder
    audio.muted = true;
    audio
      .play()
      .then(() => {
        audio!.muted = false;
      })
      .catch((err) => {
      });
  }
};

// Play the notification sound
export const playNotificationSound = (): void => {
  if (!audio) {
    return;
  }
  audio
    .play()
    .catch((err) => {
    });
};