import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface IProps {
  id?: string | number;
  title: string;
  titleClass?: string;
  total: number | string;
  subtitle: string;
  active?: boolean;
  slug?: string;
  onClick?: (arg?: any) => any;
  loading?: boolean;
  className?: string;
}

const SingleCountCard = ({
  id,
  title,
  titleClass,
  total,
  slug,
  loading,
  subtitle,
  active = false,
  className,
  onClick,
}: IProps) => {
  return (
    <div
      className={`tw-shadow-zojaShadow tw-rounded-md tw-px-5 tw-py-2 tw-cursor-pointer md:tw-px-8 md:tw-py-5 ${
        active ? 'tw-bg-isPurpleVariantTwo' : 'tw-bg-white'
      } ${className}`}
      onClick={onClick}>
      <span className={`tw-text-sm ${active ? 'tw-text-isPrimary' : 'tw-text-isGreyVariantOne'} md:tw-text-[.9rem]`}>
        {loading ? <Skeleton width={100} borderRadius={0} /> : title}
      </span>
      <h2 className={`tw-font-bold tw-text-2xl tw-text-isPrimary tw-my-1.5 ${titleClass}`}>
        {loading ? <Skeleton width={40} borderRadius={0} /> : total}
      </h2>
      <span className={`tw-text-xs ${active ? 'tw-text-isPrimary' : 'tw-text-isGreyVariantOne'} md:tw-text-[.9rem]`}>
        {loading ? <Skeleton width={100} borderRadius={0} /> : subtitle}
      </span>
    </div>
  );
};

export default SingleCountCard;