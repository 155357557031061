import { toast } from "react-toastify";

type ToastType = {
  type: "success",
  message: string;
};

const toastSettings = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
};

const showMessageSuccess = (details: ToastType) => {
  const { type, message } = details;
    toast.success(message, toastSettings);
  
};

export default showMessageSuccess;
